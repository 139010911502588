import { TOKEN_STORAGE_KEY } from '../config';

/**
 * Return HTTP request `Authorization` header value for Plooto API.
 */
export const getAuthorizationHeaderValue = (): string =>
  `Token ${localStorage.getItem(TOKEN_STORAGE_KEY)}`;

/**
 * Return HTTP request `Authorization` header for Plooto API.
 */
export const getAuthorizationHeader = (): Record<'Authorization', string> => ({
  Authorization: getAuthorizationHeaderValue(),
});
