import { IntlShape, PrimitiveType } from 'react-intl';
import ko from 'knockout';
import { createIntl, createIntlCache, MessageDescriptor } from '@formatjs/intl';
import { has, isPlainObject } from 'lodash-es';

import { getMessages, SupportedLocaleType, userPreferredLocale } from '@/features/i18n';

/**
 * Internationalization (i18n) service.
 *
 * Uses FormatJS to provide internationalization support.
 */
class I18nService {
  public isInitialized = ko.observable(false);

  /**
   * FormatJS Intl cache instance.
   *
   * Create a cache instance to be used globally across locales, and prevents memory leaks.
   *
   * @see {@link https://formatjs.io/docs/intl#createintlcache}
   */
  private cache = createIntlCache();

  /**
   * FormatJS vanilla Intl instance.
   *
   * @see {@link https://formatjs.io/docs/intl#createintl}
   */
  public intl: IntlShape = createIntl(
    {
      locale: userPreferredLocale,
      onError: (err) => {
        // ???: We're still loading the messages, so we don't want to show an error.
        if (err.code === 'MISSING_TRANSLATION') {
          return;
        }
        console.error(err);
      },
    },
    this.cache
  );

  constructor() {
    this.init(userPreferredLocale);
  }

  private init(locale: SupportedLocaleType): void {
    getMessages(locale).then((messages) => {
      this.intl = createIntl(
        {
          locale: userPreferredLocale,
          messages,
        },
        this.cache
      );
      this.isInitialized(true);
    });
  }

  /**
   * FormatJS `intl.formatMessage` wrapper that returns a formatted message if the service is initialized, or a loading indicator if not.
   *
   * Particularly useful in Knockout view models (then in templates as method calls) or in computed observables.
   *
   * @param descriptor FormatJS message descriptor.
   * @param values `intl.formatMessage` values.
   * @returns Formatted message.
   */
  public formatMessage(descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>) {
    return this.isInitialized() ? this.intl.formatMessage(descriptor, values) : '...';
  }

  public static isMessageDescriptor(descriptor: unknown): descriptor is MessageDescriptor {
    return (
      isPlainObject(descriptor) &&
      (has(descriptor, 'id') || has(descriptor, 'defaultMessage') || has(descriptor, 'description'))
    );
  }
}

const Instance = new I18nService();

export { Instance };
export default I18nService;
