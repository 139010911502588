import { TrackEvents } from 'segmentAnalytics';

export enum FormTrackerEventTypes {
  ButtonClicked = 'BUTTON_CLICKED',
  FormPresented = 'FORM_PRESENTED',
  FormFieldCaptured = 'FORM_FIELD_CAPTURED',
  FormSubmitted = 'FORM_SUBMITTED',
  FormErrorReceived = 'FORM_ERROR_RECEIVED',
}

export const FormTrackerEventName: { [K in FormTrackerEventTypes]: TrackEvents } = {
  [FormTrackerEventTypes.ButtonClicked]: 'Button Clicked',
  [FormTrackerEventTypes.FormPresented]: 'Form Presented',
  [FormTrackerEventTypes.FormFieldCaptured]: 'Form Field Captured',
  [FormTrackerEventTypes.FormSubmitted]: 'Form Submitted',
  [FormTrackerEventTypes.FormErrorReceived]: 'Form Error Received',
};

export const FormTrackerDurandalEventName: { [K in FormTrackerEventTypes]: string } = {
  [FormTrackerEventTypes.ButtonClicked]: 'button-clicked',
  [FormTrackerEventTypes.FormPresented]: 'form-presented',
  [FormTrackerEventTypes.FormFieldCaptured]: 'form-field-captured',
  [FormTrackerEventTypes.FormSubmitted]: 'form-submitted',
  [FormTrackerEventTypes.FormErrorReceived]: 'form-error-received',
};

export enum TrackingContext {
  NewPayable = 'new-payable',
  NewReceivable = 'new-receivable',
  SignIn = 'sign-in',
}

export const TrackingContextName: { [K in TrackingContext]: string } = {
  [TrackingContext.NewPayable]: 'New Payable',
  [TrackingContext.NewReceivable]: 'New Receivable',
  [TrackingContext.SignIn]: 'Sign-In',
};
