import SegmentFormTrackingBase from '@/legacy/features/analytics/segmentTrackingBase/baseFormTracking';
import { TrackingContext } from '@/legacy/features/analytics/segmentTrackingBase/constants';
import { NewPayment } from '@/legacy/services/company/payment';
import { DisplayDateFormat, formatLocalDate } from '@/utils/formatDate';

class NewPayableFormTracker extends SegmentFormTrackingBase {
  trackingContext: TrackingContext = TrackingContext.NewPayable;

  hiddenFields: Array<string> = ['payTo'];

  private trackedSize: number = null;

  private paymentsSubscription: KnockoutSubscription;

  // ???: need to call super explicitly because it extends an abstract class
  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super();
  }

  getInstanceId(): string {
    return this.formInstanceId;
  }

  fieldChanged(fieldName: string, fieldValue: any, index: number): void {
    if (this.trackedSize === null || this.trackedSize <= index) {
      return;
    }
    this.onFieldChanged(fieldName, fieldValue, index);
  }

  compositionComplete(paymentObservable: KnockoutObservableArray<NewPayment>): void {
    this.onFormPresented(this.formName);

    paymentObservable().forEach((newPayment, index) => {
      this.trackPayment(newPayment, index);
    });

    this.trackedSize = paymentObservable().length;

    this.paymentsSubscription = paymentObservable.subscribe((newValue) => {
      const oldLength = this.trackedSize;
      this.trackedSize = newValue.length;

      if (newValue.length == oldLength + 1) {
        // old length is new item's index
        this.trackPayment(newValue[oldLength], oldLength);
      }
    });
  }

  detached(): void {
    this.paymentsSubscription.dispose();
  }

  formSubmitted(isCompleted: boolean): void {
    this.onFormSubmitted(isCompleted, { numberOfPayables: String(this.trackedSize) });
  }

  /* Private:START */
  private static readonly valueTrackedFields = [
    'isRecurring',
    'interval',
    'hasAttachment',
    'paymentOrigin',
    'amount',
    'memo',
  ];

  private static getFieldValue(fieldName: string, fieldValue: any): any {
    // Custom Tracked Fields
    if (fieldName === 'payFrom') {
      return fieldValue?.discriminator === 'PaymentSourceWallet' ? 'plootoInstant' : 'bank';
    }
    if (fieldName === 'currency') {
      return fieldValue.billCurrency || fieldValue.destination()?.currency;
    }
    if (fieldName === 'debitDate') {
      return formatLocalDate(
        fieldValue instanceof Date ? fieldValue : new Date(fieldValue),
        DisplayDateFormat.ShortDate
      );
    }
    if (fieldName === 'payTo') {
      return fieldValue.destination();
    }

    return this.valueTrackedFields.includes(fieldName) ? fieldValue : undefined;
  }

  private readonly initTrackedOrigins = ['accounting', 'fx', 'ocr'];

  private trackPayment(payment: NewPayment, index: number): void {
    this.onFieldChanged('paymentOrigin', payment.creatingModule || 'other', index);

    if (!this.initTrackedOrigins.includes(payment.creatingModule)) {
      return;
    }

    this.onFieldChanged('payTo', payment, index);
    this.onFieldChanged('currency', payment, index);
    this.onFieldChanged('amount', payment.amount, index);
    this.onFieldChanged('debitDate', payment.payDate(), index);
    this.onFieldChanged('memo', payment.memo, index);
    this.onFieldChanged('hasAttachment', payment.hasAttachments, index);
  }

  private onFieldChanged(fieldName: string, fieldValue: any, index: number): void {
    const reportValue = NewPayableFormTracker.getFieldValue(fieldName, fieldValue);
    if (reportValue !== undefined) {
      this.onFormFieldCaptured(fieldName, reportValue, index);
    }
  }
  /* Private:END */
}
export default NewPayableFormTracker;
