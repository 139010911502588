import * as NewPayableButtonTracker from '@/legacy/components/user/payments/addMultiple.buttonTracker';
import NewPayableFormTracker from '@/legacy/components/user/payments/addMultiple.formTracker';
import * as accountingSoftwareIntegrationAnalyticsEventsListener from '@/legacy/features/companies/accountingSoftware/analytics/analyticsEventsListener';
import * as subscriptionPlanAnalyticsEventsListener from '@/legacy/features/companies/subscriptionPlan/analytics/analyticsEventsListener';
import * as electronicIdVerificationAnalyticsEventsListener from '@/legacy/features/user/onboarding/analytics/analyticsEventsListener';
import * as paymentRequestAnalyticsEventsListener from '@/legacy/features/user/requests/analytics/analyticsEventsListener';
import SignInTracker from '@/legacy/features/user/signIn/signInTracker';
import * as transferFundsAnalyticsEventsListener from '@/legacy/features/user/transferFunds/analytics/analyticsEventsListener';
import * as twoFactorAuthenticationAnalyticsEventsListener from '@/legacy/features/user/twoFactorAuthentication/analytics/analyticsEventsListener';
import PaymentRequestEventService from '@/legacy/services/paymentRequestEventService';

import * as bankLoginAnalyticsEventsListener from '../companies/bankLogin/analytics/analyticsEventsListener';
import * as plootoCaptureAnalyticsEventsListener from '../companies/documents/analytics/analyticsEventsListener';

/**
 * Analytics events listeners registration
 * This gets called on main.ts
 */
const registerAnalyticsEventsListeners = {
  bind(): void {
    twoFactorAuthenticationAnalyticsEventsListener.default.bind();
    accountingSoftwareIntegrationAnalyticsEventsListener.default.bind();
    bankLoginAnalyticsEventsListener.default.bind();
    electronicIdVerificationAnalyticsEventsListener.default.bind();
    subscriptionPlanAnalyticsEventsListener.default.bind();
    paymentRequestAnalyticsEventsListener.default.bind();
    plootoCaptureAnalyticsEventsListener.default.bind();
    transferFundsAnalyticsEventsListener.default.bind();
    new NewPayableFormTracker().bindEvents();
    NewPayableButtonTracker.Instance.bindEvents();
    new PaymentRequestEventService().bindEvents();
    new SignInTracker().bindEvents();
  },
};

export default registerAnalyticsEventsListeners;
