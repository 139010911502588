/** Reset for Bootstrap's global <label> CSS. */
export const label = {
  display: 'unset',
  fontWeight: 'unset',
  marginBottom: 'unset',
  maxWidth: 'unset',
} as const;

/** Reset for Bootstrap's global <legend> CSS. */
export const legend = {
  border: 'unset',
  borderBottom: 'unset',
  color: 'unset',
  display: 'unset',
  fontSize: 'unset',
  lineHeight: 'unset',
  marginBottom: 'unset',
  padding: 'unset',
  width: 'unset',
} as const;

/** Reset for Bootstrap's global <fieldset> CSS. */
export const fieldset = {
  padding: 'unset',
  margin: 'unset',
  minWidth: 'unset',
} as const;

/** Reset for Bootstrap's global <p> CSS. */
export const p = {
  margin: 'unset',
} as const;

/** Reset for Bootstrap's global <a> CSS. */
export const a = {
  backgroundColor: 'unset',
  color: 'unset',
  textDecoration: 'unset',
} as const;
