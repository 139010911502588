/**
 * Set the Webpack public path at runtime in order to support static assets in a CDN.
 *
 * @see {@link https://webpack.js.org/guides/public-path/#on-the-fly}
 */

import { isString } from 'lodash-es';

const staticAppRootUrl = import.meta.env.APP_STATIC_APP_ROOT_URL;

/**
 * Public path with enforced trailing slash.
 *
 * Avoids bad URL concatenation from Webpack.
 * @private
 */
const publicPath =
  isString(staticAppRootUrl) && staticAppRootUrl.startsWith('https://')
    ? `${staticAppRootUrl.replace(/\/$/, '')}/`
    : '/';

// @ts-expect-error: __webpack_public_path__ is a webpack variable
// eslint-disable-next-line camelcase
__webpack_public_path__ = publicPath;
