import { Instance as CompanyService } from '@/legacy/services/company';

import { EventsService, SubscribedEvent } from './eventsService';

const COMPANY_ID_KEY = 'companyId';
const PARENT_NODE_ID_KEY = 'parentNodeId';

const instance: EventsService = {
  emit(eventType: string, payload?: unknown): void {
    try {
      const updatedPayload = payload || {};
      updatedPayload[COMPANY_ID_KEY] = CompanyService.CompanyId();
      updatedPayload[PARENT_NODE_ID_KEY] = CompanyService.AccountingFirmCompanyId();

      const event = new CustomEvent(eventType, { detail: updatedPayload });
      window.dispatchEvent(event);
    } catch (error) {
      console.error(error);
    }
  },

  on(eventType: string, callback: (event: CustomEvent) => void): SubscribedEvent {
    window.addEventListener(eventType, callback);
    return { eventType, callback };
  },

  off(subscription: SubscribedEvent): void {
    const { eventType, callback } = subscription;
    window.removeEventListener(eventType, callback);
  },
};

export default instance;
