import { createContext } from 'react';

type CompanyContextState = {
  accountingFirmCompanyId: string | null;
  companyAccountingFirmClientId: string | null;
  companyCountry: string;
  companyId: string;
  isSoleProprietorship: boolean;
  isOnFreeTrial: boolean;
  permissions?: Array<string>;
  isDualControlsEnabled?: boolean;
  maxBankAccounts: number;
};

/**
 * Context for the current company.
 */
// We have to use @ts-ignore here because it only errors for strictNullChecks: true.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore `null` is never observed: PlootoPlatformProvider is a parent of all React nodes.
const CompanyContext = createContext<CompanyContextState>(null);

export default CompanyContext;
export type { CompanyContextState };
