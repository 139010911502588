import * as segmentAnalytics from 'segmentAnalytics';

import CompanyEventService from '@/legacy/services/companyEventService';

import { FormEventType } from '../../../analytics/helper';

import SecondaryBankLoginEventName from './helper';

/**
 * Analytics events listener which triggers Segment Analytics
 * tracking for Automatic Secondary (Flinks) Bank Verifcation
 * This gets called on registerAnalyticsEventsListeners.ts and registers event handlers
 */
const analyticsEventsListener = {
  bind(): void {
    CompanyEventService.on(
      SecondaryBankLoginEventName.AutomaticBankConnectionInitiated,
      (payload: CustomEvent) => {
        segmentAnalytics.track(
          SecondaryBankLoginEventName.AutomaticBankConnectionInitiated,
          payload?.detail
        );
      }
    );

    CompanyEventService.on(
      SecondaryBankLoginEventName.AutomaticBankConnectionSubmitted,
      (payload: CustomEvent) => {
        segmentAnalytics.track(
          SecondaryBankLoginEventName.AutomaticBankConnectionSubmitted,
          payload?.detail
        );
      }
    );

    CompanyEventService.on(
      SecondaryBankLoginEventName.AutomaticBankVerificationCancelled,
      (payload: CustomEvent) => {
        segmentAnalytics.track(
          SecondaryBankLoginEventName.AutomaticBankVerificationCancelled,
          payload?.detail
        );
      }
    );

    CompanyEventService.on(
      SecondaryBankLoginEventName.AutomaticBankVerificationCompleted,
      (payload: CustomEvent) => {
        segmentAnalytics.track(FormEventType.ButtonClicked, payload?.detail);
      }
    );

    CompanyEventService.on(
      SecondaryBankLoginEventName.AutomaticBankVerificationForceVoidCheque,
      (payload: CustomEvent) => {
        segmentAnalytics.track(FormEventType.ButtonClicked, payload?.detail);
      }
    );
  },
};

export default analyticsEventsListener;
