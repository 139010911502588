import app from 'durandal/app';
import { debounce } from 'lodash-es';

import { Instance as CompanyService } from '@/legacy/services/company';
import { Instance as UserService } from '@/legacy/services/user';

// From Pendo docs:
//
//   Due to the way Pendo processes metadata values, Visitor and Account IDs must be unique per API
//   key. Excluding is insufficient to address ID collisions.
//
//   If there are ID collisions between testing and production environments, consider adding a
//   prefix or suffix to indicate each non-production environment.
//
//   Pendo tracks guide interaction behavior at the Visitor ID level. Non-production environments
//   should also contain unique IDs to ensure visitors receive a consistent guide experience.
//
//   https://support.pendo.io/hc/en-us/articles/360031862352-Pendo-in-multiple-environments-for-development-and-testing
//
// Production ("PROD") identifiers are sent to Pendo unaltered, but other environments are prefixed
// to avoid mingling data.
const prefixId = (id: string) => {
  const prefix = import.meta.env.PENDO_ID_PREFIX || 'DEV';
  return prefix !== 'PROD' ? `${prefix}~${id}` : id;
};

export function initializePendoListeners() {
  const identifyWithPendo = debounce(() => {
    const userId = UserService.UserId();
    const companyId = CompanyService.CompanyId();

    if (userId == null) {
      return;
    }

    window.pendo?.identify({
      visitor: {
        id: prefixId(userId),
        name: UserService.DisplayFirstName(),
        // G2 recommends URI-encoding the email value for some reason.
        // See: https://documentation.g2.com/docs/pendo#tracking-reviews-generated-via-pendo
        email: encodeURIComponent(UserService.DisplayEmail()),
      },
      account: companyId
        ? {
            id: prefixId(companyId),
            name: CompanyService.DisplayName(),
            // It's fine here
            // eslint-disable-next-line no-nested-ternary
            nodeType: CompanyService.AccountingFirmCompanyId()
              ? 'child'
              : CompanyService.isAccountingFirm()
                ? 'parent'
                : 'root',
            isCreditCardPayableEnabled: CompanyService.profile()?.isCreditCardPayableEnabled(),
            isPaymentsCapable: CompanyService.membership()?.isPaymentsCapable(),
            isPaymentsQueueable: CompanyService.membership()?.isPaymentsQueueable(),
            planName: CompanyService.profile()?.subscriptionFeatures()?.subscriptionName(),
          }
        : undefined,
      parentAccount: CompanyService.AccountingFirmCompanyId()
        ? { id: prefixId(CompanyService.AccountingFirmCompanyId()) }
        : undefined,
    });
  }, 250);

  app.on('user:signedIn', () => {
    identifyWithPendo();
  });

  app.on('companyProfile:companyChanged', () => {
    identifyWithPendo();
  });
}
