import * as segmentAnalytics from 'segmentAnalytics';

import { EventName, FormEventType } from '@/legacy/features/analytics/helper';
import { EventType as TwoFactorAuthenticationEventType } from '@/legacy/features/user/twoFactorAuthentication/twoFactorAuthentication';
import EventsService from '@/legacy/services/eventsService';
/**
 * Analytics events listener which triggers Segment Analytics
 * tracking for a user's Two Factor Authentication
 * This gets called on registerAnalyticsEventsListeners.ts and registers event handlers
 */
const analyticsEventsListener = {
  bind(): void {
    EventsService.on(TwoFactorAuthenticationEventType.UserTwoFactorAuthenticationSuccess, () => {
      segmentAnalytics.track(EventName.TwoFactorAuthenticationSuccess, {
        source: FormEventType.ButtonClicked,
      });
    });
  },
};

export default analyticsEventsListener;
