/**
 * Handle environment variable values that are defined as "null"
 *
 * This is useful for environment variables that are defined as "null" in
 * Azure Pipeline Library variables, as they are for the old `PlootoConfig`
 * object.
 *
 * @param value Environment variable value
 * @returns `null` if the value is "null", otherwise the value provided as is
 */
const nullOrString = (value) => {
  if (value === 'null') {
    return null;
  }
  return value;
};

export default nullOrString;
