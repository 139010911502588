/**
 * Service to ensure observability of browser errors. It captures all thrown errors and sends them
 * over to Microsoft Application Insights.
 */
class ErrorTrackingService {
  /**
   * Must be called to start tracking errors.
   */
  public static bind(): void {
    window.addEventListener('error', ErrorTrackingService.onError);
  }

  private static onError(this: Window, event: ErrorEvent): true {
    const { error: exception } = event;
    if (window.appInsights) {
      window.appInsights.trackException({ exception });
    }
    return true;
  }

  /**
   * Must be called to clean up after this service is not needed.
   */
  public static unbind(): void {
    window.removeEventListener('error', ErrorTrackingService.onError);
  }
}

export default ErrorTrackingService;
