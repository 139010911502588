class SessionStorageService {
  public static setItem(key: string, data: unknown): void {
    try {
      sessionStorage.setItem(key, JSON.stringify(data));
    } catch (e) {
      console.error('Error saving to sessionStorage', e);
    }
  }

  public static getItem<T>(key: string): T | undefined {
    try {
      const json = sessionStorage.getItem(key);
      if (!json) {
        return undefined;
      }
      return JSON.parse(json);
    } catch (e) {
      console.error('Error getting from sessionStorage', e);
      return undefined;
    }
  }

  public static removeItem(key: string): void {
    try {
      sessionStorage.removeItem(key);
    } catch (e) {
      console.error('Error removing from sessionStorage', e);
    }
  }

  public static clear(): void {
    try {
      sessionStorage.clear();
    } catch (e) {
      console.error('Error clearing sessionStorage', e);
    }
  }
}

export default SessionStorageService;
