import { CompositionContext } from 'durandal/composition';
import * as dialog from 'plugins/dialog';

// This is a cut down version of the old dialogFull plugin, which was a copy of the built-in dialog
// plugin but customized for the full-screen style modal windows the app uses. The "full-screen"
// behavior is implemented in the caller, src/App/modals/manage/popup.ts

dialog.addContext('modal', {
  addHost(theDialog: dialog.Dialog) {
    var $body = $('body');
    var $host = $('<div class="modalHost"></div>')
      .css({ 'z-index': dialog.getNextZIndex() })
      .appendTo($body);
    theDialog.host = $host.get(0);
  },
  removeHost(theDialog: dialog.Dialog) {
    ko.removeNode(theDialog.host);
  },
  compositionComplete(child: HTMLElement, parent: HTMLElement, context: CompositionContext) {
    var theDialog = dialog.getDialog(context.model);
    var $child = $(child);
    setTimeout(function () {
      $child.css('visibility', 'visible');
      $(theDialog.host).css('opacity', '1');
    }, 0);
  },
});
