import * as uuid from 'uuid';

import SegmentTrackingBase from '@/legacy/features/analytics/segmentTrackingBase/baseTracking';
import { FormTrackerEventTypes } from '@/legacy/features/analytics/segmentTrackingBase/constants';
import CompanyEventService from '@/legacy/services/companyEventService';

abstract class SegmentFormTrackingBase extends SegmentTrackingBase {
  // falls back to the context name if formName is not provided
  protected formName?: string = undefined;

  protected hiddenFields: Array<string> = [];

  protected readonly formInstanceId: string;

  protected constructor() {
    super();
    this.formInstanceId = uuid.v4();
  }

  protected onClickCaptured(buttonName: string, formName?: string): void {
    const contextEventName = this.getContextEventName(FormTrackerEventTypes.ButtonClicked);

    CompanyEventService.emit(contextEventName, {
      buttonName,
      formInstanceId: this.formInstanceId,
      formName: this.getFormName(formName),
    });
  }

  protected onFormFieldCaptured(
    fieldName: string,
    fieldValue: string,
    index?: number,
    formName?: string
  ): void {
    const contextEventName = this.getContextEventName(FormTrackerEventTypes.FormFieldCaptured);
    const hideValue = this.hiddenFields.includes(fieldName);
    const payloadFieldName = typeof index === 'number' ? `[${index + 1}]${fieldName}` : fieldName;

    CompanyEventService.emit(contextEventName, {
      formInstanceId: this.formInstanceId,
      formName: this.getFormName(formName),
      fieldName: payloadFieldName,
      fieldValue: hideValue ? undefined : fieldValue,
      isFieldEmpty: !fieldValue,
    });
  }

  protected onFormError(errorMessage: string, formName?: string): void {
    const contextEventName = this.getContextEventName(FormTrackerEventTypes.FormErrorReceived);

    CompanyEventService.emit(contextEventName, {
      errorMessage: errorMessage || 'invalid or empty error message',
      formInstanceId: this.formInstanceId,
      formName: this.getFormName(formName),
    });
  }

  protected onFormSubmitted(
    isFormComplete: boolean,
    customFields: Record<string, string>,
    formName?: string
  ): void {
    const contextEventName = this.getContextEventName(FormTrackerEventTypes.FormSubmitted);

    const eventOptions = {
      formInstanceId: this.formInstanceId,
      formName: formName ?? this.formName ?? this.getContextName(),
      fieldName: 'submissionStatus',
      fieldValue: isFormComplete ? 'completed' : 'incomplete',
    };

    if (customFields) {
      Object.assign(eventOptions, customFields);
    }

    CompanyEventService.emit(contextEventName, eventOptions);
  }

  protected onFormPresented(formName?: string): void {
    const contextEventName = this.getContextEventName(FormTrackerEventTypes.FormPresented);

    CompanyEventService.emit(contextEventName, {
      formInstanceId: this.formInstanceId,
      formName: formName ?? this.formName ?? this.getContextName(),
    });
  }

  private getFormName(formName: string): string {
    return formName ?? this.formName ?? this.getContextName();
  }

  bindEvents(): void {
    this.bindEventsBase([
      FormTrackerEventTypes.ButtonClicked,
      FormTrackerEventTypes.FormPresented,
      FormTrackerEventTypes.FormSubmitted,
      FormTrackerEventTypes.FormFieldCaptured,
    ]);
  }
}

export default SegmentFormTrackingBase;
