import * as segmentAnalytics from 'segmentAnalytics';

import CompanyEventService from '@/legacy/services/companyEventService';

import { FormEventType } from '../../../analytics/helper';

import {
  TransferFundsContext,
  TransferFundsEventType,
  TransferFundsFormEventButtonName,
  TransferFundsReviewEventButtonName,
} from './helpers';

/**
 * Analytics events listener which triggers Segment Analytics
 * tracking for transfer funds
 * This gets called on registerAnalyticsEventsListeners.ts and registers event handlers
 */
const analyticsEventsListener = {
  bind(): void {
    CompanyEventService.on(
      TransferFundsEventType.TransferFundsFillFormBack,
      (payload: CustomEvent) => {
        segmentAnalytics.track(FormEventType.ButtonClicked, {
          buttonName: TransferFundsFormEventButtonName.Back,
          context: TransferFundsContext.TransferFunds,
          ...payload?.detail,
        });
      }
    );

    CompanyEventService.on(
      TransferFundsEventType.TransferFundsFillContinue,
      (payload: CustomEvent) => {
        segmentAnalytics.track(FormEventType.ButtonClicked, {
          buttonName: TransferFundsFormEventButtonName.Next,
          context: TransferFundsContext.TransferFunds,
          ...payload?.detail,
        });
      }
    );

    CompanyEventService.on(
      TransferFundsEventType.TransferFundsReviewBack,
      (payload: CustomEvent) => {
        segmentAnalytics.track(FormEventType.ButtonClicked, {
          buttonName: TransferFundsReviewEventButtonName.Back,
          context: TransferFundsContext.Confirm,
          ...payload?.detail,
        });
      }
    );

    CompanyEventService.on(
      TransferFundsEventType.TransferFundsReviewConfirm,
      (payload: CustomEvent) => {
        segmentAnalytics.track(FormEventType.ButtonClicked, {
          buttonName: TransferFundsReviewEventButtonName.Confirm,
          context: TransferFundsContext.Confirm,
          ...payload?.detail,
        });
      }
    );
  },
};

export default analyticsEventsListener;
