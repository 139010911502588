import { useMemo } from 'react';
import { Sync } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

import LoadingButton from '@/components/LoadingButton';
import type { RealtimeServiceMode } from '@/legacy/services/realtime/models/realtimeServiceMode';

interface Props {
  mode: RealtimeServiceMode;
  reconnect: () => void;
}

const OfflineMessage = ({ mode, reconnect }: Props) => {
  const offlineMessage = useMemo(() => {
    switch (mode) {
      case 'disconnected':
        return "You're offline! Try reconnecting ...";
      case 'terminated':
        return null;
      case 'reconnecting':
      default:
        return 'Attempting to reconnect ...';
    }
  }, [mode]);

  if (offlineMessage == null) {
    return null;
  }

  return (
    <Box
      sx={{
        zIndex: 20000,
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'center',
        pointerEvents: 'none',
      }}
    >
      <Box
        sx={{
          py: 2,
          px: 3,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderRadius: 1,
          bgcolor: 'white',
          pointerEvents: 'initial',
        }}
      >
        <LoadingButton
          type="button"
          startIcon={<Sync />}
          color="primary"
          variant="contained"
          onClick={reconnect}
          loading={mode === 'reconnecting'}
        >
          Reconnect
        </LoadingButton>
        <Typography
          sx={{
            verticalAlign: 'middle',
            ml: 1,
          }}
          component="span"
        >
          {offlineMessage}
        </Typography>
      </Box>
    </Box>
  );
};

export default OfflineMessage;
