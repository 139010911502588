import React, { Suspense } from 'react';

import { NewFeatureFlagGateProps } from '../types/NewFeatureFlagGateProps';

const NewFeatureFlagGate = React.lazy(() => import('./NewFeatureFlagGate'));

/**
 * A wrapper around the NewFeatureFlagGate component that adds a Suspense fallback.
 * @param props @see {@link NewFeatureFlagGateProps}
 */
const NewFeatureFlagGateWithSuspense = (props: NewFeatureFlagGateProps) => (
  // eslint-disable-next-line react/destructuring-assignment
  <Suspense fallback={props.loadingChildren}>
    <NewFeatureFlagGate {...props} />
  </Suspense>
);

export default NewFeatureFlagGateWithSuspense;
