/* eslint-disable new-cap, no-underscore-dangle */
/**
 * Durandal system module overrides
 */
import * as system from 'durandal/system';

const ModuleLoaders = new Map<string, () => Promise<any>>();

globalThis.declareModules = (declarations: Record<string, () => Promise<any>>): void => {
  for (const [moduleId, moduleLoader] of Object.entries(declarations)) {
    if (!ModuleLoaders.has(moduleId)) {
      ModuleLoaders.set(moduleId, moduleLoader);
    }
  }
};

/**
 * Load app module via ESM
 * @param moduleId Module identifier
 * @returns App module ESM import Promise
 */
const loadModule = async (moduleId: string) => {
  let modulePromise: Promise<any>;
  const moduleFunc = ModuleLoaders.get(moduleId);
  if (moduleFunc == null) {
    throw new Error(
      `[durandal/overrides/system] No module declaration for "${moduleId}". Add a module declaration in the viewmodel referencing this Durandal module:\n\ndeclareModules({\n  '${moduleId}': () => import('@/legacy/${moduleId}'),\n})\n\nIf this is a Durandal internal module, add it to "src/vendor/durandal/overrides/module-declarations.ts"`
    );
  }
  const module = await moduleFunc();
  const acquiredModule = module?.default ?? module;
  if (!moduleId.endsWith('.html')) {
    system.setModuleId(acquiredModule, moduleId);
  }
  return acquiredModule;
};

/**
 * Durandal system module `acquire` override for Webpack
 * @returns ES2020-based `import` wrapped in a Durandal (jQuery) Promise
 */
// @ts-expect-error: TS complains that we can't override `system` methods, but we can ;-)
system.acquire = function () {
  const first = arguments[0];
  const arrayRequest = system.isArray(first);
  const moduleIds: Array<string> = arrayRequest ? first : Array.prototype.slice.call(arguments, 0);
  const arrayResponse = moduleIds.length > 1 || arrayRequest;
  return this.defer((deferred: DurandalDeferred<any>) => {
    Promise.all(moduleIds.map(loadModule)).then(
      (modules) => deferred.resolve(arrayResponse ? modules : modules[0]),
      (reason) => deferred.reject(reason)
    );
  }).promise();
};

/**
 * Durandal system module `resolveObject` override for ESM and our own `ViewModel` hack
 * @param module Loaded Durandal module
 * @returns The module, an initialized module, or a `ViewModel`'s view module
 */
// @ts-expect-error: TS complains that we can't override `system` methods, but we can ;-)
system.resolveObject = function (module) {
  // ???: Handle ESM `default` module imports
  module = module.default || module;

  if (system.isFunction(module)) {
    return new module();
  }
  if (module.ViewModel !== undefined) {
    const retVal = new module.ViewModel();
    retVal.__moduleId__ = module.__moduleId__;
    return retVal;
  }
  return module;
};
