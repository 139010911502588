import { StrictMode, Suspense } from 'react';
import { createHashRouter, RouterProvider } from 'react-router-dom';

import PlootoPlatformProvider from '@/providers/PlootoPlatformProvider';
import { reactRoutes } from '@/routes/react';

const router = createHashRouter(reactRoutes, {
  future: {
    // Opt into the feature flags that the docs tell us to.
    // https://reactrouter.com/en/main/routers/create-browser-router#future
    v7_fetcherPersist: true,
    v7_normalizeFormMethod: true,
    v7_partialHydration: true,
    // Not available on HashRouter
    // v7_prependBasename: true,
    v7_relativeSplatPath: true,
  },
});

export const Shell = () => (
  <StrictMode>
    <PlootoPlatformProvider>
      {/* TODO: come up with a fallback page, perhaps the same one as in index.hbs */}
      <Suspense fallback={null}>
        <RouterProvider router={router} />
      </Suspense>
    </PlootoPlatformProvider>
  </StrictMode>
);
