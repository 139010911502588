interface SubscribedEvent {
  eventType: string;
  callback: (event: Event) => void;
}

/**
 * Service used for global frontend communication using events.
 *
 * The `off` method ***must*** be called at the end of the lifetime to prevent leakage.
 */
interface EventsService {
  /**
   * Send the event with an optional payload as the last parameter.
   */
  emit: (eventType: string, payload?: unknown) => void;
  /**
   * Listen to events.
   */
  on: (eventType: string, callback: (event: CustomEvent) => void) => SubscribedEvent;
  /**
   * Unsubscribe events. Must be called to prevent leaks.
   */
  off: (subscription: SubscribedEvent) => void;
}

const instance: EventsService = {
  emit(eventType: string, payload?: unknown): void {
    try {
      let event: CustomEvent;

      if (payload) {
        event = new CustomEvent(eventType, { detail: payload });
      } else {
        event = new CustomEvent(eventType);
      }

      window.dispatchEvent(event);
    } catch (error) {
      console.error(error);
    }
  },

  on(eventType: string, callback: (event: CustomEvent) => void): SubscribedEvent {
    window.addEventListener(eventType, callback);
    return { eventType, callback };
  },

  off(subscription: SubscribedEvent): void {
    const { eventType, callback } = subscription;
    window.removeEventListener(eventType, callback);
  },
};

export default instance;
export type { EventsService, SubscribedEvent };
