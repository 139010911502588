import SegmentFormTrackingBase from '@/legacy/features/analytics/segmentTrackingBase/baseFormTracking';
import {
  FormTrackerEventTypes,
  TrackingContext,
  TrackingContextName,
} from '@/legacy/features/analytics/segmentTrackingBase/constants';
import EventsService from '@/legacy/services/eventsService';

class SignInTracker extends SegmentFormTrackingBase {
  public trackingContext: TrackingContext = TrackingContext.SignIn;

  public trackingContextName = TrackingContextName[this.trackingContext];

  // ???: The way SegmentFormTrackingBase works, we need to call `super`
  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super();
  }

  public onEmailCaptured(value: string): void {
    super.onFormFieldCaptured('email', value);
  }

  public onPasswordCaptured(isEmpty: boolean): void {
    super.onFormFieldCaptured('password', isEmpty ? '' : 'omitted for security reasons');
  }

  public onFail(errorMessage: string): void {
    super.onFormError(errorMessage, this.trackingContextName);
  }

  public onSignInPresented(): void {
    super.onFormPresented(this.trackingContextName);
  }

  public onSignInComplete(userId: string): void {
    super.onFormSubmitted(true, { userId }, this.trackingContextName);
  }

  public onSignInClicked(): void {
    EventsService.emit(this.getContextEventName(FormTrackerEventTypes.ButtonClicked), {
      buttonName: 'Sign In',
      context: 'Sign-in',
      formInstanceId: this.formInstanceId,
    });
  }

  public onSignUpClicked(): void {
    super.onClickCaptured('Sign Up', this.trackingContextName);
  }

  public onForgotPasswordClicked(): void {
    super.onClickCaptured('Forgot Password', this.trackingContextName);
  }
}

export default SignInTracker;
