import * as segmentAnalytics from 'segmentAnalytics';

import {
  FormTrackerDurandalEventName,
  FormTrackerEventName,
  FormTrackerEventTypes,
  TrackingContext,
  TrackingContextName,
} from '@/legacy/features/analytics/segmentTrackingBase/constants';
import CompanyEventService from '@/legacy/services/companyEventService';

abstract class SegmentTrackingBase {
  abstract trackingContext: TrackingContext;

  getContextEventName = (eventName: FormTrackerEventTypes): string =>
    `${this.trackingContext}:${FormTrackerDurandalEventName[eventName]}`;

  getContextName = (): string => TrackingContextName[this.trackingContext];

  protected bindEventsBase = (eventNames: Array<FormTrackerEventTypes>): void => {
    eventNames.forEach((eventName) => {
      const contextEventName = this.getContextEventName(eventName);
      CompanyEventService.on(contextEventName, (event) => {
        segmentAnalytics.track(FormTrackerEventName[eventName], event?.detail);
      });
    });
  };
}

export default SegmentTrackingBase;
