/** Currency code accepted by Plooto */
enum CurrencyCode {
  None = '',
  USD = 'USD',
  CAD = 'CAD',
  EUR = 'EUR',
  AED = 'AED',
  AUD = 'AUD',
  BRL = 'BRL',
  CHF = 'CHF',
  CNY = 'CNY',
  DKK = 'DKK',
  GBP = 'GBP',
  HKD = 'HKD',
  HRK = 'HRK',
  ILS = 'ILS',
  INR = 'INR',
  JPY = 'JPY',
  KRW = 'KRW',
  MXN = 'MXN',
  NOK = 'NOK',
  NZD = 'NZD',
  PHP = 'PHP',
  PLN = 'PLN',
  SEK = 'SEK',
  SGD = 'SGD',
  TWD = 'TWD',
  UAH = 'UAH',
  ZAR = 'ZAR',
  RUB = 'RUB',
}

export default CurrencyCode;
