﻿define(function (requirejs) {
	var module = {};
	module.send = function (hitType, eventCategory, eventAction, eventLabel, eventValue) {
		//currently only 'send' events are handled ('set' and 'create' are taken care of by Google Tag Manager)
		if (hitType === 'event' || hitType === 'action') {
			var data = {
				'event': 'GAevent'
			};
			if (hitType !== undefined) data['hitType'] = hitType;
			if (eventCategory !== undefined) data['eventCategory'] = eventCategory;
			if (eventAction !== undefined) data['eventAction'] = eventAction;
			if (eventLabel !== undefined) data['eventLabel'] = eventLabel;
			if (eventValue !== undefined) data['eventValue'] = eventValue;
			window.dataLayer.push(data);
		} else if (hitType === 'pageview') {
			window.dataLayer.push({
				'event': 'pageview',
				'page': eventCategory
			});
		}
	};
	module.set = function (dimension, id) {
		var data = {
			'event': 'GAevent'
		};
		if (dimension !== undefined) data['hitType'] = dimension;
		if (id !== undefined) data['eventCategory'] = id;
		window.dataLayer.push(data);
	};
	return module;
});