import SegmentFormTrackingBase from '@/legacy/features/analytics/segmentTrackingBase/baseFormTracking';
import { TrackingContext } from '@/legacy/features/analytics/segmentTrackingBase/constants';

class PaymentRequestEventService extends SegmentFormTrackingBase {
  public trackingContext: TrackingContext = TrackingContext.NewReceivable;

  // ???: need to call super explicitly because it extends an abstract class
  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super();
  }

  public onClickCaptured(buttonName: string, formName?: string): void {
    super.onClickCaptured(buttonName, formName);
  }

  public onFormError(errorMessage: string, formName?: string): void {
    super.onFormError(errorMessage, formName);
  }

  public onFormFieldCaptured(
    fieldName: string,
    fieldValue: string,
    _: number,
    formName?: string
  ): void {
    super.onFormFieldCaptured(fieldName, fieldValue, null, formName);
  }

  public onFormPresented(formName?: string): void {
    super.onFormPresented(formName);
  }

  public onFormSubmitted(
    isFormComplete: boolean,
    customFields: Record<string, string>,
    formName?: string
  ): void {
    super.onFormSubmitted(isFormComplete, customFields, formName);
  }
}

export default PaymentRequestEventService;
