import { isString } from 'lodash-es';

const apiEnv = import.meta.env.APP_APIM_API_ENV;

/**
 * Azure API Management (APIM) object for mixing into HTTP request headers.
 *
 * It adds an `X-API-ENV` header with the value of `APP_APIM_API_ENV` environment variable if it is set.
 */
export const apimHttpHeader = isString(apiEnv) && apiEnv.length > 0 ? { 'X-API-ENV': apiEnv } : {};

/** Plooto API authorization token storage key name (in `localStorage`). */
export const TOKEN_STORAGE_KEY = '_token';
