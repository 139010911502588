import browserLang from 'browser-lang';

import { FALLBACK_LOCALE, SupportedLocale, supportedLocales, SupportedLocaleType } from '../config';

const isPseudoLocalizationEnabled = () => {
  try {
    return localStorage.getItem('isPseudoLocalizationEnabled') === 'True';
  } catch {
    return false;
  }
};

/**
 * The user's preferred application language (locale), based on their browser language.
 */
const userPreferredLocale = isPseudoLocalizationEnabled()
  ? SupportedLocale.EnXA
  : (browserLang({
      languages: supportedLocales.slice(),
      fallback: FALLBACK_LOCALE,
    }) as SupportedLocaleType);

export default userPreferredLocale;
