import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash-es';

import CompanyContext from '@/providers/CompanyContext';
import UserContext from '@/providers/UserContext';

import FeatureToggleService from '../services/FeatureToggleService';
import FeatureFlag from '../types/FeatureFlag';

type UseFeatureFlagOptions = {
  /**
   * A key type to use for feature flag check.
   * @default 'current-company'
   */
  keyType?: 'current-company' | 'current-user' | 'anonymous' | 'custom';
  /**
   * A key identifying an entity for which the current feature state is being requested when `keyType` is `custom`.
   */
  key?: string;
};

/**
 * Checks if a feature flag is enabled for the current company.
 * @param featureFlag Feature flag to check.
 * @param options @see {@link UseFeatureFlagOptions}
 * @returns Whether the feature flag is enabled.
 */
const useFeatureFlag = (
  featureFlag: FeatureFlag,
  { keyType = 'current-company', key }: UseFeatureFlagOptions = {}
): { isFeatureEnabled: boolean; isLoading: boolean } => {
  const companyContext = useContext(CompanyContext);
  const userContext = useContext(UserContext);

  let queryKey;
  let queryFn;
  switch (keyType) {
    case 'current-company':
      queryKey = ['featureFlag', featureFlag, companyContext?.companyId];
      queryFn = () => FeatureToggleService.isFeatureEnabledForCurrentCompany(featureFlag);
      break;
    case 'current-user':
      queryKey = ['featureFlag', featureFlag, userContext?.userId];
      queryFn = () => FeatureToggleService.isFeatureEnabledForCurrentUser(featureFlag);
      break;
    case 'anonymous':
      queryKey = ['featureFlag', featureFlag, 'anonymous'];
      queryFn = () => FeatureToggleService.isFeatureEnabledForAnonymous(featureFlag);
      break;
    default:
      if (!isNil(key)) {
        queryKey = ['featureFlag', featureFlag, keyType, key];
        queryFn = () => FeatureToggleService.isFeatureEnabled(featureFlag, key);
      } else {
        throw new Error('`key` must be provided when `keyType` is `custom`');
      }
  }

  const { data: isFeatureEnabled, isLoading } = useQuery<boolean>({ queryKey, queryFn });

  return { isFeatureEnabled, isLoading };
};

export type { UseFeatureFlagOptions };
export default useFeatureFlag;
