import SegmentButtonTrackingBase from '@/legacy/features/analytics/segmentTrackingBase/baseButtonTracking';
import { TrackingContext } from '@/legacy/features/analytics/segmentTrackingBase/constants';

enum NewPayableButtonTypes {
  AddPayment,
  MassPay,
  Continue,
  BackToEdit,
}

const newPayableButtonTypeNameMap: { [key in NewPayableButtonTypes]: string } = {
  [NewPayableButtonTypes.AddPayment]: 'Add Another Payment',
  [NewPayableButtonTypes.MassPay]: 'Setup Mass Pay',
  [NewPayableButtonTypes.Continue]: 'Continue to Confirmation',
  [NewPayableButtonTypes.BackToEdit]: 'Back to Edit Payable',
};

class NewPayableButtonTracker extends SegmentButtonTrackingBase<NewPayableButtonTypes> {
  trackingContext: TrackingContext = TrackingContext.NewPayable;
}

export const Instance = new NewPayableButtonTracker(newPayableButtonTypeNameMap);
export { NewPayableButtonTypes };
