enum SubscriptionEventDiscriminator {
  SelectUpgradeSubscription = 'sub:select-upgrade-subscription',
  SelectDowngradeSubscription = 'sub:select-downgrade-subscription',
  CancelSubscription = 'sub:cancel-subscription',
  CancelDowngrade = 'sub:cancel-downgrade',
  CancelUpgrade = 'sub:cancel-upgrade',

  DismissUpgrade = 'sub:dismiss-upgrade',
  ConfirmUpgrade = 'sub:confirm-upgrade',
  DismissDowngrade = 'sub:dismiss-downgrade',
  ConfirmDowngrade = 'sub:confirm-downgrade',

  DismissCancelDowngrade = 'sub:dismiss-cancel-downgrade',
  ConfirmCancelDowngrade = 'sub:confirm-cancel-downgrade',
  DismissCancelUpgrade = 'sub:dismiss-cancel-upgrade',
  ConfirmCancelUpgrade = 'sub:confirm-cancel-upgrade',

  ConfirmCancelSubscription = 'sub:confirm-cancel-subscription',
  RemainSubscribed = 'sub:remain-subscribed',
  SubscriptionCancellationReason = 'sub:cancellation-reason',
}

enum SubscriptionEventButtonName {
  UpgradeSubscriptionPlan = 'Upgrade Subscription Plan',
  DowngradeSubscriptionPlan = 'Downgrade Subscription Plan',
  CancelSubscription = 'Cancel Subscription',
  CancelUpgrade = 'Cancel Upgrade',
  CancelDowngrade = 'Cancel Downgrade',
  DismissUpgrade = 'Dismiss Upgrade',
  ConfirmUpgrade = 'Confirm Upgrade',
  DismissDowngrade = 'Dismiss Downgrade',
  ConfirmDowngrade = 'Confirm Downgrade',
  DismissCancelDowngrade = 'Dismiss Cancel Downgrade',
  DismissCancelUpgrade = 'Dismiss Cancel Upgrade',
  ConfirmCancelSubscription = 'Confirm Cancel Subscription',
  RemainSubscribed = 'Remain Subscribed',
  SubscriptionCancellationReason = 'Subscription Cancellation Reason',
}

enum SubscriptionContext {
  BillingAndSubscription = 'Billing and Subscription',
  DowngradeSubscription = 'Downgrade Subscription',
  UpgradeSubscription = 'Upgrade Subscription',
  CancelDowngrade = 'Cancel Downgrade',
  CancelUpgrade = 'Cancel Upgrade',
  CancelPlootoMonthlySubscription = 'Cancel Plooto Monthly Subscription',
}

export { SubscriptionEventDiscriminator, SubscriptionEventButtonName, SubscriptionContext };
