// This exists in a separate file to break cyclic imports with PlootoPlatformProvider.
import createCache from '@emotion/cache';
import { QueryClient } from '@tanstack/react-query';

/** Global emotion cache to use with all React roots. */
export const emotionCache = createCache({
  key: 'mui',
  prepend: true,
});

/** Global TanStack QueryClient to use with all React roots. */
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Disable automatic refetching on window focus.
      refetchOnWindowFocus: false,
    },
  },
});
