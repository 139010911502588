/**
 * Add HTML element to the DOM
 * @param type DOM tag type
 * @param innerText Inner text of the element
 * @param attributes Array of attributes to be added to the element
 * @private
 */
function addHtmlElement(type, innerText, attributes) {
  const body = document.getElementsByTagName('body')[0];
  const element = document.createElement(type);
  element.innerText = innerText;

  if (attributes) {
    for (let i = 0; i <= attributes.length - 1; i += 1) {
      element.setAttributeNode(attributes[i]);
    }
  }

  body.appendChild(element);
}

/**
 * Show environment label on the top of the page
 * @param label Environment label
 */
function showEnvironment(label) {
  const attribute = document.createAttribute('style');
  attribute.value = 'position: fixed; z-index: 6001; background-color: red; top: 0;';
  const dataTestId = document.createAttribute('data-testid');
  dataTestId.value = 'environment';
  addHtmlElement('div', label, [attribute, dataTestId]);
}

export default showEnvironment;
