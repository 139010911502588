import yn from 'yn';

/**
 * Parse an old-style `PlootoConfig` variable in AZDO to a boolean. Mostly for use with feature toggles.
 *
 * @returns `null` for any variable set to `"null"`, as that, historically, indicates a feature is available for everyone; otherwise, returns a boolean as derived from `yn`.
 *
 * @see {@link IsFeatureEnabled} in `utils/plooto` for details on how this is used.
 */
const parseBoolean = (value): boolean | null => {
  if (value === 'null') {
    return null;
  }
  return yn(value) ?? false;
};

export default parseBoolean;
