import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material';

// This is unfortunately a distinct React root that needs to initialize a ThemeProvider.
import { PlootoTheme_DoNotUse_ThisIsOnlyForSettingUpTheMaterialTheme_CodeReviewerPleaseEnforceThis as theme } from '@/config/theme';
import type { RealtimeServiceMode } from '@/legacy/services/realtime/models/realtimeServiceMode';
import { emotionCache } from '@/providers/PlootoPlatformProvider.caches';

import OfflineMessage from './components/OfflineMessage';

interface Props {
  mode: RealtimeServiceMode;
  reconnect: () => void;
}

const OfflineMessageProvider = ({ mode, reconnect }: Props) => (
  <CacheProvider value={emotionCache}>
    <ThemeProvider theme={theme}>
      <OfflineMessage mode={mode} reconnect={reconnect} />
    </ThemeProvider>
  </CacheProvider>
);

export default OfflineMessageProvider;
