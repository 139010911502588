import React from 'react';
import ReactDOM from 'react-dom/client';
import app from 'durandal/app';
import type { HubConnection, HubConnectionState } from '@microsoft/signalr';
import { castArray, noop } from 'lodash-es';
import { v4 as uuid } from 'uuid';

import OfflineMessageProvider from '@/legacy/features/realtime/components/OfflineMessageProvider';
import AuthFetchService from '@/legacy/services/authFetch';
import RealtimeEvent from '@/legacy/services/realtime/models/realtimeEvent';
import { RealtimeServiceMode } from '@/legacy/services/realtime/models/realtimeServiceMode';

import eventService from '../eventsService';

interface NegotiateResponse {
  url: string;
  accessToken: string;
}

async function joinGroup(groupId: string): Promise<void> {
  await AuthFetchService.httpPost(
    `${import.meta.env.REALTIME_URL_V2}/api/v1/groups/${
      import.meta.env.REALTIME_ENVIRONMENT_NAME
    }:${groupId}`
  );
}

async function negotiate(userId: string): Promise<NegotiateResponse> {
  const options = {
    'X-Plooto-UserId': userId,
  };
  const response = await AuthFetchService.httpGet(
    `${import.meta.env.REALTIME_URL_V2}/api/v1/negotiate`,
    options
  );
  return response.json();
}

class RealtimeService {
  private hubConnection: HubConnection;

  private $offlineMessage: JQuery;

  private reactRoot: ReactDOM.Root;

  private reconnectWithLastUserId = noop;

  public async connect(userId: string): Promise<void> {
    this.reconnectWithLastUserId = this.connect.bind(this, userId);
    const { HubConnectionBuilder } = await import('@microsoft/signalr');
    const [, response] = await Promise.all([this.disconnect(), negotiate(userId)]);

    this.hubConnection = new HubConnectionBuilder()
      .withUrl(response.url, { accessTokenFactory: () => response.accessToken })
      .withAutomaticReconnect()
      .build();

    this.hubConnection.on('Update', (a, b) => {
      const eventName = `realtime:${a}`;

      // [EXP-19424] The payload might come in as an array (multiple messages together as one) so we have to break them up and send them one at a time
      const eventPayloads = castArray(b);

      for (const eventPayload of eventPayloads) {
        app.trigger(eventName, eventPayload);
        eventService.emit(eventName, eventPayload);
      }
    });

    this.hubConnection.onclose(this.onOffline);
    this.hubConnection.onreconnected(this.onOnline);
    this.registerEventHandlers();

    try {
      await this.hubConnection.start();
      this.onOnline();
    } catch (error) {
      console.error('[RealtimeService]', error);
      this.onOffline();
    }
  }

  public async disconnect(disposing = false): Promise<void> {
    if (!this.hubConnection) {
      return;
    }

    const { hubConnection } = this;

    // Clearing hubConnection will suppress the offline message. We want this for the sign-out case
    // (disposing), but not when disconnecting prior to reconnecting.
    if (disposing) {
      this.hubConnection = undefined;
    }

    try {
      await hubConnection.stop();
    } finally {
      this.onOffline();
      this.unregisterEventHandlers();
    }
  }

  public subscribeToCompany(companyId: string): Promise<void> {
    return joinGroup(companyId);
  }

  public subscribeToUser(userId: string): Promise<void> {
    return joinGroup(userId);
  }

  public isConnected(): boolean {
    return this.hubConnection?.state === 'Connected';
  }

  private registerEventHandlers() {
    window.addEventListener('offline', this.onOffline);
    window.addEventListener('online', this.onOnline);
  }

  private unregisterEventHandlers() {
    window.removeEventListener('offline', this.onOffline);
    window.removeEventListener('online', this.onOnline);
  }

  private onOnline = (): void => {
    app.trigger(RealtimeEvent.Online);
    this.clearOfflineMessages();
  };

  private onOffline = (): void => {
    app.trigger(RealtimeEvent.Offline);
    this.renderOfflineMessage();
  };

  private renderOfflineMessage() {
    if (!this.$offlineMessage) {
      this.$offlineMessage = $('<div id="RealtimeOfflineMessage" />');
      this.$offlineMessage.appendTo('body');
      this.reactRoot = ReactDOM.createRoot(this.$offlineMessage[0], { identifierPrefix: uuid() });
    }

    this.reactRoot.render(
      React.createElement(OfflineMessageProvider, {
        mode: this.getMode(),
        reconnect: this.reconnectWithLastUserId,
      })
    );
  }

  private clearOfflineMessages() {
    if (!this.$offlineMessage) {
      return;
    }

    this.reactRoot.unmount();
    this.reactRoot = undefined;
    this.$offlineMessage.remove();
    this.$offlineMessage = undefined;
  }

  private getMode(): RealtimeServiceMode {
    if (!this.hubConnection) {
      return 'terminated';
    }

    // This cast allows us to defer the entirety of @microsoft/signalr from the initial app bundle.
    if (this.hubConnection.state === ('Reconnecting' as HubConnectionState.Reconnecting)) {
      return 'reconnecting';
    }

    if (this.hubConnection.state === ('Disconnected' as HubConnectionState.Disconnected)) {
      return 'disconnected';
    }

    return '';
  }
}

const Instance = new RealtimeService();

export default Instance;
