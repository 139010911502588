import React from 'react';
import ReactDOM from 'react-dom/client';
import * as viewEngine from 'durandal/viewEngine';
import * as router from 'plugins/router';
import ko from 'knockout';
import $ from 'jquery';
import { mapValues } from 'lodash-es';
import { v4 as uuid } from 'uuid';

import withPlootoPlatformProvider from '@/providers/withPlootoPlatformProvider';
import { flushSync } from 'react-dom';

if (!$ || !ko || !React || !ReactDOM) {
  throw new Error(
    'You need jQuery, knockout, React and ReactDOM for the react-durandal library to function.'
  );
}

/**
 * Add a custom Knockout binding for React components. This allows for more flexibility to simply drop in
 * react components into existing Durandal viewmodels rather than needing to write React
 * viewmodels from scratch.
 * Takes a single param: Component (React Component) (required) - this is an actual React
 *		component instance.
 * Optionally, pass in a props object as a sibling binding.
 *
 * Usage:
 * <div data-bind="react: ReactComponent, props: { info: model().property }"></div>
 */
function addKoHooks() {
  const reactRootsMap: WeakMap<HTMLElement, ReactDOM.Root> = new WeakMap();

  ko.bindingHandlers.react = {
    init(container: HTMLElement) {
      // Properly dispose of React elements after knockout element is removed (this will call unmount in React)
      ko.utils.domNodeDisposal.addDisposeCallback(container, () => {
        const root = reactRootsMap.get(container);
        root?.unmount();
        reactRootsMap.delete(container);
      });

      return { controlsDescendantBindings: true };
    },

    update(container: HTMLElement, valueAccessor, allBindings) {
      const Component = ko.unwrap<React.FunctionComponent>(valueAccessor());
      // React components expect values, not Knockout observables, so unwrap any props that hold Knockout observables.
      // Note: do this shallowly, as ko.toJS also deep-clones objects, which breaks prototype chains and instanceof
      // checks (e.g. fancy types like ReactNode).
      const props: Record<string, unknown> = mapValues(allBindings.get('props'), ko.unwrap);
      props.onChange = function (newValue) {
        // ???: this is inert due to the above mapValues (and ko.toJS() before it). Perhaps this intends to invoke
        // against the unmarshalled prop values? i.e. `allBindings.get('props').value`
        if (props.value && ko.isObservable(props.value)) {
          props.value(newValue);
        }
      };

      let root = reactRootsMap.get(container);
      if (root == null) {
        root = ReactDOM.createRoot(container, { identifierPrefix: uuid() });
        reactRootsMap.set(container, root);
      }

      const render = () =>
        root.render(React.createElement(withPlootoPlatformProvider(Component), props));

      // Allow callers to force React to render synchronously so that element is finalized before
      // Knockout moves on to the next binding, otherwise they will operate with incomplete nodes.
      // For example, user/payees/save triggers user/payees/payeeDocumentsSettings to re-render
      // PayeeDocumentSettingsDisplayFragment, a large component, to render several times in quick
      // succession. Since this is async, React partially renders it, gets interrupted, cancels, and
      // restarts rendering, causing the DOM to get deleted-and-created, which causes ugly visual
      // thrashing. This setting slows down overall rendering, making it more like React <18, but
      // eliminates this "visual tearing".
      const useSyncRendering = Boolean(allBindings.get('useSyncRendering'));
      if (useSyncRendering) {
        flushSync(render);
      } else {
        render();
      }
    },
  };
}

// Create a placeholder view. This prevents Durandal from throwing up a "view not found" message
// before we can replace it with our React component. It also also makes it slightly easier to
// hook into.
const placeholderEl = $('<div/>');
const placeholderViewName = 'views/reactviewplaceholder';
placeholderEl.attr('data-view', placeholderViewName);
// The default view convention for Durandal looks for a Require-processed view, so we need to
// add the appropriate prefix and extension to get it to find our placeholder
const placeholderViewRequireString = `text!${placeholderViewName}.html`;
viewEngine.putViewInCache(placeholderViewRequireString, placeholderEl[0]);

/** Insert our React component into the view! */
function composeReact(routeConfig, props) {
  const routeId = routeConfig.moduleId.replace('/', '');
  let insertionElement = $(`#${routeId}`)[0];
  if (!insertionElement) {
    $(`[data-view='${placeholderViewName}']`).replaceWith(`<div id='${routeId}'></div>`);
    // @ts-expect-error incorrect overload used; TS doesn't think jQuery returns `Array`
    [insertionElement] = $(`#${routeId}`);
  }

  // Get the actual component class by requiring it.
  // eslint-disable-next-line import/no-dynamic-require, global-require, @typescript-eslint/no-var-requires
  const component = require(routeConfig.moduleId);
  const root = ReactDOM.createRoot(insertionElement);
  root.render(React.createElement(withPlootoPlatformProvider(component), props));
}

// Callback for "router:route:activating" Durandal Router event. When we activate a viewmodel
// that is a React component, we want to be able to send the component the route activation data
// without needing to modify the component to hook into the Durandal lifecycle by adding
// activate, deactivate, etc.
function onActivatingCallback(instance, instruction) {
  if (instruction.config.react) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const props = this;
    const { route } = instruction.config;
    const routeParamRegex = /:\w*/g;
    instance.viewUrl = placeholderViewName;
    instance.activate = function () {
      // Any route params are passed in as individual arguments. Props, however,
      // need to be an object. But we also want to associate the correct route
      // params with the right props.
      // eslint-disable-next-line prefer-rest-params
      const args = Array.prototype.slice.call(arguments);
      const routeParams = route.match(routeParamRegex);
      routeParams.forEach((param, index) => {
        // remove the semicolon via substr to get the proper param name
        props[param.substr(1)] = args[index];
      });
    };
  }
}

// Callback for "router:navigation:composition-complete". This is where we want to actually do
// the insertion of the instantiated React component into the DOM.
function onCompositionCompleteCallback(instance, instruction) {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const props = this;
  if (instruction.config.react) {
    composeReact(instruction.config, props);
  }
}

const durandalReact = {
  // Simple API: just initialize the library and start using React components! Woo!
  initialize() {
    addKoHooks();
    // Hook into Durandal router by wrapping the buildNavigationModel function. That way, when
    // the routes are built, we automatically add the necessary hooks to get the React
    // components into the app.
    const originalBuildNavigationModelFn = router.buildNavigationModel;
    const props = {};
    // @ts-expect-error: TS complains that we can't override `router` methods, but we can ;-)
    router.buildNavigationModel = function (defaultOrder) {
      const builtRouter = originalBuildNavigationModelFn.call(router, defaultOrder);
      builtRouter
        .on('router:route:activating', onActivatingCallback.bind(props))
        .on('router:navigation:composition-complete', onCompositionCompleteCallback.bind(props));
      return router;
    };
  },
};

export default durandalReact;
