/**
 * Application environment variables
 * @module config/env
 */

import yn from 'yn';

import nullOrString from './helpers/nullOrString';
import parseBoolean from './helpers/parseBoolean';
import parseStringArray from './helpers/parseStringArray';

/**
 * Plooto application URL with fallback to current origin
 */
const APP_URL = import.meta.env.APP_URLS_APP_URL ?? window.location.origin;

/** Hours offset from UTC */
const HOURS_OFFSET = Number(import.meta.env.APP_HOURS_OFFSET);

/** Maximum file upload size in bytes */
const MAX_UPLOAD_SIZE_BYTES = Number(import.meta.env.APP_MAX_UPLOAD_SIZE_BYTES);

/** Google reCAPTCHA v2 key */
const RECAPTCHA_V2_KEY = nullOrString(import.meta.env.APP_RECAPTCHA_V2KEY);

/** Supported currencies */
const SUPPORTED_CURRENCIES: Array<string> = parseStringArray(
  import.meta.env.CURRENCIES_SUPPORTED_CURRENCIES
);
/** Supported accounting currencies */
const SUPPORTED_ACCOUNTING_CURRENCIES: Array<string> = parseStringArray(
  import.meta.env.CURRENCIES_SUPPORTED_ACCOUNTING_CURRENCIES
);

/** Credit card configuration */
const CREDIT_CARDS = {
  clientKey: import.meta.env.FEATURE_CREDIT_CARD_CLIENT_KEY,
  environment: import.meta.env.FEATURE_CREDIT_CARD_ENVIRONMENT,
  apiUrl: import.meta.env.FEATURE_CREDIT_CARD_API_URL,
  apiPrefix: import.meta.env.FEATURE_CREDIT_CARD_API_PREFIX,
};

const FEATURE_ONBOARDING_DASHBOARD_CHECKBOXES: Array<number> = parseStringArray(
  import.meta.env.FEATURE_ONBOARDING_DASHBOARD_CHECKBOXES
);

const FILE_STORAGE = {
  MAX_UPLOAD_SIZE_BYTES: Number(import.meta.env.FEATURE_FILE_STORAGE_MAX_UPLOAD_SIZE_BYTES),
  API_BASE_URL: import.meta.env.FEATURE_FILE_STORAGE_API_URL,
  API_VERSION: import.meta.env.FEATURE_FILE_STORAGE_API_VERSION,
};

/**
 * Feature map for FeatureInTesting entries, which can load custom bundles by using their featureName as key and bundleName as prefix
 *
 * @deprecated use Split.io feature flags instead
 * TODO(PLP-33954): Remove this once all feature flags are migrated to Split.io. It may already be the case that this is no longer used.
 */
const FEATURE_MAP = {
  FEATURE_OCR: { prefix: import.meta.env.FEATURE_OCR_PREFIX },
};

/**
 * Feature toggles for variables from before the Split.io era.
 *
 * Stands in for old `PlootoConfig` feature toggle variables, and used with the `IsFeatureEnabled` function in the `utils/plooto` module.
 *
 * NOTE: The prop names are replicas of the old variables, and the `FEATURE_` prefixes are kept as-is for consistency.
 *
 * TODO(PLP-33773): All of these variables are candidates for removal, as they are unlikely to change in future.
 */
const FEATURE_TOGGLES = {
  FEATURE_PLOOTO_INSTANT_BANK_FEEDS: parseStringArray(
    import.meta.env.FEATURE_PLOOTO_INSTANT_BANK_FEEDS
  ),
  FEATURE_SEND_REMINDER_EMAIL_TO_APPROVERS: parseBoolean(
    import.meta.env.FEATURE_SEND_REMINDER_EMAIL_TO_APPROVERS
  ),
  LIMITED_DOMESTIC_PAYMENTS_CAPABILITY_ONBOARDING: parseBoolean(
    import.meta.env.LIMITED_DOMESTIC_PAYMENTS_CAPABILITY_ONBOARDING
  ),
  SECONDARY_BANK_LOGIN: parseBoolean(import.meta.env.BANK_SECONDARY_BANK_LOGIN),

  /**
   * [PLP-34280] Controls visibility of onboarding for credit card banners
   * This is null in production (enabled for all customers)
   */
  FEATURE_PAYMENT_REQUESTS_CREDIT_CARDS: parseStringArray(
    import.meta.env.FEATURE_PAYMENT_REQUESTS_CREDIT_CARDS
  ),
};

/** Secondary bank login URLs */
const SECONDARY_BANK_LOGIN_URLS = {
  /** Canadian banks */
  CA: import.meta.env.BANK_SECONDARY_BANK_LOGIN_CANADA,
  /** US banks */
  US: import.meta.env.BANK_SECONDARY_BANK_LOGIN_US,
};

/**
 * Whether to log errors to Durandal
 *
 * If set to `true`, output console.log and exceptions to
 * console (which we can forward to app insights with a middleware)
 */
const DURANDAL_LOG_ERRORS = yn(import.meta.env.DURANDAL_LOG_ERRORS);

export {
  APP_URL,
  HOURS_OFFSET,
  MAX_UPLOAD_SIZE_BYTES,
  RECAPTCHA_V2_KEY,
  SUPPORTED_CURRENCIES,
  SUPPORTED_ACCOUNTING_CURRENCIES,
  DURANDAL_LOG_ERRORS,
  CREDIT_CARDS,
  FEATURE_ONBOARDING_DASHBOARD_CHECKBOXES,
  FEATURE_TOGGLES,
  FEATURE_MAP,
  FILE_STORAGE,
  SECONDARY_BANK_LOGIN_URLS,
};
