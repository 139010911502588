import * as system from 'durandal/system';

import * as plootoUtils from '@/legacy/utils/plooto';

const API_PREFIX = 'v1';

export class ExternalAuthenticationService {
  public GetDomainVerification(domain: string): JQueryPromise<any> {
    return system
      .defer((dfd) => {
        $.ajax({
          url: `${
            import.meta.env.APP_URLS_API_URL
          }/${API_PREFIX}/externalAuthentication/?domain=${domain}`,
          type: 'GET',
          contentType: 'application/json',
          dataType: 'json',
        })
          .done((response) => {
            if (plootoUtils.IsNullOrUndefined(response) || response.error === true) {
              dfd.reject(response);
              return;
            }

            dfd.resolve(response);
          })
          .fail(() => {
            dfd.reject();
          });
      })
      .promise();
  }
}

export const Instance = new ExternalAuthenticationService();
