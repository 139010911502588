import {
  // This is the only place that this import is allowed.
  // eslint-disable-next-line no-restricted-imports
  LoadingButton as MuiLoadingButton,
  LoadingButtonProps as MuiLoadingButtonProps,
} from '@mui/lab';
import { ButtonProps as MuiButtonProps } from '@mui/material';

// Just using LoadingButtonProps hangs TypeScript, so use this dumb work-around.
// https://github.com/mui/material-ui/issues/30038
type LoadingButtonProps = MuiButtonProps & Omit<MuiLoadingButtonProps, keyof MuiButtonProps>;

/**
 * LoadingButton, but with `variant="contained"` by default, and wraps children in <span> to
 * mitigate https://github.com/facebook/react/issues/11538
 *
 * Demos:
 *
 * - [Button](https://mui.com/material-ui/react-button/)
 *
 * API:
 *
 * - [LoadingButton API](https://mui.com/material-ui/api/loading-button/)
 * - inherits [Button API](https://mui.com/material-ui/api/button/)
 */
const LoadingButton = ({ variant = 'contained', children, ...props }: LoadingButtonProps) => (
  <MuiLoadingButton variant={variant} {...props}>
    <span>{children}</span>
  </MuiLoadingButton>
);
LoadingButton.displayName = 'LoadingButton';

export type { LoadingButtonProps };
export default LoadingButton;
