import * as segmentAnalytics from 'segmentAnalytics';

import CompanyEventService from '@/legacy/services/companyEventService';

import { FormEventType } from '../../../analytics/helper';

import { OcrEventName, OcrEventsServiceEventType } from './helper';
/**
 * Analytics events listener which triggers Segment Analytics
 * tracking for Plooto Capture events.
 * This gets called on registerAnalyticsEventsListeners.ts and registers event handlers
 */
const analyticsEventsListener = {
  bind(): void {
    CompanyEventService.on(
      OcrEventsServiceEventType.NavigateToDocumentTab,
      (payload: CustomEvent) => {
        segmentAnalytics.track(OcrEventName.NavigateToDocumentTab, {
          source: FormEventType.ButtonClicked,
          companyId: payload?.detail,
        });
      }
    );

    CompanyEventService.on(
      OcrEventsServiceEventType.CaptureDocumentDeleted,
      (payload: CustomEvent) => {
        segmentAnalytics.track(OcrEventName.CaptureDocumentDeleted, {
          source: FormEventType.ButtonClicked,
          ...payload.detail,
        });
      }
    );

    CompanyEventService.on(
      OcrEventsServiceEventType.CaptureDocumentUploaded,
      (payload: CustomEvent) => {
        segmentAnalytics.track(OcrEventName.CaptureDocumentUploaded, {
          source: FormEventType.ButtonClicked,
          ...payload.detail,
        });
      }
    );

    CompanyEventService.on(
      OcrEventsServiceEventType.CaptureDocumentPublished,
      (payload: CustomEvent) => {
        segmentAnalytics.track(OcrEventName.CaptureDocumentPublished, {
          source: FormEventType.ButtonClicked,
          ...payload.detail,
        });
      }
    );

    CompanyEventService.on(
      OcrEventsServiceEventType.CaptureDocumentArchiveVisited,
      (payload: CustomEvent) => {
        segmentAnalytics.track(OcrEventName.CaptureDocumentArchiveVisited, {
          source: FormEventType.ButtonClicked,
          ...payload.detail,
        });
      }
    );

    CompanyEventService.on(
      OcrEventsServiceEventType.CaptureDocumentSupplierChanged,
      (payload: CustomEvent) => {
        segmentAnalytics.track(OcrEventName.CaptureDocumentSupplierChanged, {
          source: FormEventType.ButtonClicked,
          ...payload.detail,
        });
      }
    );

    CompanyEventService.on(
      OcrEventsServiceEventType.CaptureDocumentSupplierRuleChanged,
      (payload: CustomEvent) => {
        segmentAnalytics.track(OcrEventName.CaptureDocumentSupplierRuleChanged, {
          source: FormEventType.ButtonClicked,
          ...payload.detail,
        });
      }
    );
  },
};

export default analyticsEventsListener;
