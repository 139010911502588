import { memoize } from 'lodash-es';

const getClientCache = new Map<string, Promise<SplitIO.IBrowserClient>>();
const getClient = memoize(async (key: string): Promise<SplitIO.IBrowserClient> => {
  // Split's SDK is enormous, so defer it.
  const { default: splitIoFactory } = await import('./splitIoFactory');
  const client = splitIoFactory().client(key);

  try {
    // exception needs to be propagated internally to SDK as mentioned in .ready() docs.
    await client.ready().catch((e) => {
      throw e;
    });
  } catch {
    return null;
  }

  return client;
});
getClient.cache = getClientCache;

/** Releases resources used by the {@link SplitIO.IBrowserClient} instances. */
export async function destroySplitClients(): Promise<void> {
  const promises = Array.from(getClientCache.values());
  getClientCache.clear();
  await Promise.all(
    promises.map((promise) =>
      promise
        .then((client) => client?.destroy())
        .catch(() => {
          // Intentionally ignore this rejection.
        })
    )
  );
}

/** Returns a {@link SplitIO.IBrowserClient} for the provided `key`. */
export const getSplitClient: (key: string) => Promise<SplitIO.IBrowserClient> = getClient;
