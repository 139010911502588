/**
 * Supported languages (locales) for the Plooto application.
 *
 * Language codes are based on ISO 639-1.
 * @enum
 */
const SupportedLocale = {
  // English (Canada)
  EnCA: 'en-CA',
  // English (Pseudolocalization)
  EnXA: 'en-XA',
} as const;

/**
 * Supported languages (locales) for the Plooto application.
 */
type SupportedLocaleType = (typeof SupportedLocale)[keyof typeof SupportedLocale];

/**
 * The default language (locale) to use when the user's browser language is not supported.
 * @constant
 */
const FALLBACK_LOCALE = SupportedLocale.EnCA;

/**
 * Languages (locales) supported by the Plooto application.
 */
const supportedLocales: ReadonlyArray<SupportedLocaleType> = Object.values(SupportedLocale);

/** Returns true if the locale string is a supported locale for the Plooto application. */
const isSupportedLocale = (locale: string): locale is SupportedLocaleType =>
  (supportedLocales as ReadonlyArray<string>).includes(locale);

export type { SupportedLocaleType };
export { FALLBACK_LOCALE, SupportedLocale, supportedLocales, isSupportedLocale };
