import * as dialog from 'plugins/dialog';

import BaseModal from '@/legacy/modals/BaseModal';

declareModules({
  'modals/styled/titleMessage.html': () => import('@/legacy/modals/styled/titleMessage.html'),
});

interface ViewModelParams {
  title: string;

  message?: string;
  messageHtml?: string;

  continueText?: string;
  cancelText?: string;

  closeCallback?: (confirmed: boolean) => void;

  svgIcon?: string;
  svgIconSymbol?: string;
  svgIconSymbolLarge?: string;
}

class StyledModal extends BaseModal {
  view = 'modals/styled/titleMessage';

  public params: ViewModelParams;

  public continueText: string;

  public cancelText: string;

  public closeCallback: (confirmed: boolean) => void;

  constructor(params: ViewModelParams) {
    super();
    this.params = params;
    this.continueText = params.continueText || 'Continue';
    this.cancelText = params.cancelText || undefined;
    this.closeCallback = params.closeCallback;
  }

  public onClickClose(): void {
    dialog.close(this, false);
    this.closeCallback?.(false);
  }

  public onClickContinue(): void {
    dialog.close(this, true);
    this.closeCallback?.(true);
  }

  public static show(params: ViewModelParams): JQueryPromise<boolean> {
    return dialog.show(new StyledModal(params));
  }
}

export default StyledModal;
export { ViewModelParams };
