import { useEffect, useState } from 'react';
import * as ko from 'knockout';
import { isNil } from 'lodash-es';

/**
 * React hook that synchronizes a Knockout observable with React state, and automatically unsubscribes when the component unmounts.
 *
 * @param observable Any Knockout observable (observable, observableArray, computed observables)
 * @returns The current value of the observable; returns undefined if provided a nullish value
 */
export function useSyncKoObservable<T>(
  observable: KnockoutObservable<T> | KnockoutReadonlyObservable<T> | null | undefined
): T {
  if (!isNil(observable) && !ko.isObservable(observable)) {
    throw new Error(
      `useSyncKoObservable must be used with a Knockout observable. Got ${observable}`
    );
  }

  const [state, setState] = useState<T>(observable?.peek());

  useEffect(() => {
    const subscription = observable?.subscribe((newState) => {
      setState(newState);
    });

    return () => {
      subscription?.dispose();
    };
  }, [observable, setState]);

  return state;
}
