import { PropsWithChildren } from 'react';
import { styled } from '@mui/system';
import { MaterialDesignContent, SnackbarProvider, VariantType } from 'notistack';

const variants: Array<VariantType> = ['success', 'error', 'warning', 'info'];

const StyledMaterialDesignContent = styled(MaterialDesignContent)(({ theme }) =>
  variants.reduce(
    (accumulatedStyles, variant) => ({
      ...accumulatedStyles,
      [`&.notistack-MuiContent-${variant}`]: {
        backgroundColor: theme.palette[variant].main,
        color: theme.palette[variant].contrastText,
      },
    }),
    {
      '&.notistack-MuiContent': {
        maxWidth: theme.breakpoints.values.md,
      },
    }
  )
);

const Components = variants.reduce(
  (accumulatedComponents, variant) => ({
    ...accumulatedComponents,
    [variant]: StyledMaterialDesignContent,
  }),
  {}
);

/**
 * Plooto-themed notistack SnackbarProvider.
 *
 * !!!: Must be used within a ThemeProvider.
 */
const ThemedSnackbarProvider = ({ children }: PropsWithChildren) => (
  <SnackbarProvider preventDuplicate Components={Components} autoHideDuration={5000}>
    {children}
  </SnackbarProvider>
);

export { ThemedSnackbarProvider, variants };
