import { useEffect } from 'react';
import history from 'plugins/history';

/** Resolves when the Durandal history plugin is ready to be used. */
const historyReady = new Promise<void>((resolve) => {
  const handle = setInterval(() => {
    if (history.active) {
      clearInterval(handle);
      resolve();
    }
  }, 16);
});

/** Calls into the Durandal router to force it to update its state against the current URL. */
const syncDurandalHistory = () => historyReady.then(() => history.checkUrl());

/** Delegates rendering back to Durandal by swapping root elements. */
export const DeferToDurandal = () => {
  useEffect(() => {
    let didCancel = false;
    const durandalRoot = document.getElementById('applicationHost');
    syncDurandalHistory().then(() => {
      if (!didCancel) {
        if (durandalRoot != null) {
          durandalRoot.style.display = 'unset';
        }
      }
    });
    return () => {
      didCancel = true;
      if (durandalRoot != null) {
        durandalRoot.style.display = 'none';
      }
      syncDurandalHistory();
    };
  }, []);
  return null;
};
