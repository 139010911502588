import * as segmentAnalytics from 'segmentAnalytics';

import { DialogEventType, FormEventType } from '@/legacy/features/analytics/helper';
import CompanyEventService from '@/legacy/services/companyEventService';

const analyticsEventsListener = {
  bind(): void {
    /**
     * Shared analytics events listener for all
     * company specific segment events
     * This gets called on main.ts and registers event handlers
     * emitted from CompanyEventsService.ts
     *
     * Generic Event Types
     * {@link FormEventType.ButtonClicked}
     * {@link FormEventType.FormPresented}
     * {@link FormEventType.FormFieldCaptured}
     * {@link FormEventType.FormSubmitted}
     * {@link FormEventType.FormErrorReceived}
     */

    CompanyEventService.on(FormEventType.ButtonClicked, (payload: CustomEvent) => {
      segmentAnalytics.track(FormEventType.ButtonClicked, payload?.detail);
    });
    CompanyEventService.on(FormEventType.FormPresented, (payload: CustomEvent) => {
      segmentAnalytics.track(FormEventType.FormPresented, payload?.detail);
    });

    CompanyEventService.on(FormEventType.FormFieldCaptured, (payload: CustomEvent) => {
      segmentAnalytics.track(FormEventType.FormFieldCaptured, payload?.detail);
    });

    CompanyEventService.on(FormEventType.FormSubmitted, (payload: CustomEvent) => {
      segmentAnalytics.track(FormEventType.FormSubmitted, payload?.detail);
    });

    CompanyEventService.on(FormEventType.FormErrorReceived, (payload: CustomEvent) => {
      segmentAnalytics.track(FormEventType.FormErrorReceived, payload?.detail);
    });

    CompanyEventService.on(DialogEventType.DialogPresented, (payload: CustomEvent) => {
      segmentAnalytics.track(DialogEventType.DialogPresented, payload?.detail);
    });

    CompanyEventService.on(DialogEventType.DialogDismissed, (payload: CustomEvent) => {
      segmentAnalytics.track(DialogEventType.DialogDismissed, payload?.detail);
    });

    CompanyEventService.on(DialogEventType.DialogAcknowledged, (payload: CustomEvent) => {
      segmentAnalytics.track(DialogEventType.DialogAcknowledged, payload?.detail);
    });
  },
};

export default analyticsEventsListener;
