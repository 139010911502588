import { RouteObject } from 'react-router-dom';

import { lazyImport } from '@/utils/lazyImport';

const { BigRedBox } = lazyImport(() => import('./components/BigRedBox'), 'BigRedBox');

const reactShellDemoRoutes: Array<RouteObject> = [
  {
    path: 'big-red-box',
    Component: BigRedBox,
  },
];

export default reactShellDemoRoutes;
