/**
 * Add a reference element to the DOM
 * @param target Target tag name
 * @param type Type of the element
 * @param path URL path
 * @param customAttributes Array of custom attributes to be added to the element
 */
function addReferenceElement(
  target: string,
  type: string,
  path: string,
  customAttributes?: Array<any>
) {
  const targetElement = document.getElementsByTagName(target)[0];
  let element;

  switch (type) {
    case 'stylesheet':
    case 'icon':
      element = document.createElement('link');
      element.rel = type;
      element.href = path;
      break;
    case 'script':
      element = document.createElement('script');
      element.async = false;
      element.defer = true;
      element.src = path;
      break;
    default:
      element = document.createElement(type);
      break;
  }

  if (customAttributes) {
    for (let i = 0; i <= customAttributes.length - 1; i += 1) {
      element.setAttributeNode(customAttributes[i]);
    }
  }

  targetElement.appendChild(element);
}

export default addReferenceElement;
