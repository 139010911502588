import { apimHttpHeader, getAuthorizationHeaderValue } from '@/features/api';

/**
 * Helper service which adds 'Authorization' header to HTTP requests
 * @deprecated Use `ApiService` instead.
 */
export default class AuthFetchService {
  /** @deprecated Use `ApiService` instead. */
  static async httpGet(
    url: string,
    headers?: HeadersInit,
    body?: BodyInit
  ): Promise<globalThis.Response> {
    return AuthFetchService.makeRequest('GET', url, body, headers);
  }

  /** @deprecated Use `ApiService` instead. */
  static async httpPatch(
    url: string,
    body?: BodyInit,
    headers?: HeadersInit
  ): Promise<globalThis.Response> {
    return AuthFetchService.makeRequest('PATCH', url, body, headers);
  }

  /** @deprecated Use `ApiService` instead. */
  static async httpPut(
    url: string,
    body?: BodyInit,
    headers?: HeadersInit
  ): Promise<globalThis.Response> {
    return AuthFetchService.makeRequest('PUT', url, body, headers);
  }

  /** @deprecated Use `ApiService` instead. */
  static async httpPost(
    url: string,
    body?: BodyInit,
    headers?: HeadersInit
  ): Promise<globalThis.Response> {
    return AuthFetchService.makeRequest('POST', url, body, headers);
  }

  /** @deprecated Use `ApiService` instead. */
  static async httpDelete(
    url: string,
    body?: BodyInit,
    headers?: HeadersInit
  ): Promise<globalThis.Response> {
    return AuthFetchService.makeRequest('DELETE', url, body, headers);
  }

  private static async makeRequest(
    method: string,
    url: string,
    body?: BodyInit,
    headers?: HeadersInit
  ): Promise<globalThis.Response> {
    const requestHeaders = new Headers(headers);
    Object.entries(apimHttpHeader).forEach(([name, value]) => {
      requestHeaders.append(name, value);
    });
    requestHeaders.append('Authorization', getAuthorizationHeaderValue());

    return fetch(url, {
      method,
      headers: requestHeaders,
      body,
    });
  }
}
