enum Comparison {
  Contains = 'Contains',
  DoesNotContain = 'DoesNotContain',
  Equals = 'Equals',
  GreaterOrEqual = 'GreaterOrEqual',
  GreaterThan = 'GreaterThan',
  LessOrEqual = 'LessOrEqual',
  LessThan = 'LessThan',
  NotEquals = 'NotEquals',
  StartsWith = 'StartsWith',
}

interface PaginationFilters {
  type: string;
  value: string;
  valueTo?: string; // optional, used in date range filters
  comparison?: Comparison; // date range filters do not have comparison
}

interface PaginationOptionsParams {
  filters?: Array<PaginationFilters>;
  page?: number;
  resultsPerPage?: number;
}

type PaginationOptions = PaginationOptionsParams | null;

enum SortOrder {
  Ascending,
  Descending,
}
interface SortDetail {
  type: number;
  order: SortOrder;
}

interface SearchPaginationOptions extends PaginationOptionsParams {
  searchQuery: string;
  sortDetails: Array<SortDetail>;
}

export type { PaginationFilters, PaginationOptions, SearchPaginationOptions, SortDetail };
export { Comparison, SortOrder };
