enum OcrEventName {
  NavigateToDocumentTab = 'OCR - Navigate to Document Tab',

  CaptureDocumentUploaded = 'Capture Document Uploaded',
  CaptureDocumentPublished = 'Capture Document Published',
  CaptureDocumentDeleted = 'Capture Document Deleted',
  CaptureDocumentArchiveVisited = 'Capture Document Archive Visited',
  CaptureDocumentSupplierChanged = 'Capture Document Supplier Changed',
  CaptureDocumentSupplierRuleChanged = 'Capture Document Supplier Rule Changed',
}

enum OcrEventsServiceEventType {
  NavigateToDocumentTab = 'ocr:navigation:document-tab',

  CaptureDocumentUploaded = 'ocr:document-uploaded',
  CaptureDocumentPublished = 'ocr:document-published',
  CaptureDocumentDeleted = 'ocr:document-deleted',
  CaptureDocumentArchiveVisited = 'ocr:archive-visited',
  CaptureDocumentSupplierChanged = 'ocr:supplier-changed',
  CaptureDocumentSupplierRuleChanged = 'ocr:supplier-rule-changed',
}

export { OcrEventName, OcrEventsServiceEventType };
