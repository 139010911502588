import { ApiService } from '@/features/api';

enum TwoFactorAuthenticationType {
  Sms = 'Sms',
  Otp = 'Otp',
}

enum TwoFactorAuthenticationStatus {
  NotSet = 'NotSet',
  PendingConfirmation = 'PendingConfirmation',
  Enabled = 'Enabled',
  Disabled = 'Disabled',
  Temporary = 'Temporary',
  EnforcedEnabled = 'EnforcedEnabled',
}

enum EventType {
  UserTwoFactorAuthenticationSuccess = 'user:twoFactorAuthentication:success',
}

interface TwoFactorAuthentication {
  id: string;
  status: TwoFactorAuthenticationStatus;
  enabledDate: string | null;
  disabledDate: string | null;
  type: TwoFactorAuthenticationType;
  displayName: string;
  isDefault: boolean;
}

interface AddTwoFactorAuthenticationRequest {
  code: string;
  temporaryMfaSetupId: string;
  type: TwoFactorAuthenticationType;
}

interface SmsSettings {
  phoneNumber: string;
  phoneCountry: string;
  shouldCallUser?: boolean;
}

interface SendDisableCodeRequest {
  shouldCallUser: boolean;
}

interface TwoFactorStatusSettingsBindingModel {
  code: string;
  status: TwoFactorAuthenticationStatus;
  twoFactorAuthenticationIdToUpdate: string;
}

interface TwoFactorStatusSettingsBindingRequest {
  code: string;
  rememberDevice?: boolean;
}

interface GenerateOtpCodeResponse {
  secretKey: string;
  temporaryMfaSetupId: string;
}

interface GenerateSmsCodeResponse {
  temporaryMfaSetupId: string;
}

enum UserSessionTwoFactorStatus {
  NotRequired = 'NotRequired',
  Required = 'Required',
  Authorized = 'Authorized',
  SignupRequired = 'SignupRequired',
  InGracePeriod = 'InGracePeriod',
  ComplianceRequired = 'ComplianceRequired',
}

interface AuthenticateResponse {
  status: UserSessionTwoFactorStatus;
}

const apiPrefix = '/v1';

const apiPath = import.meta.env.APP_URLS_API_URL + apiPrefix;

class TwoFactorAuthenticationService {
  public static async getTwoFactorAuthentications(): Promise<Array<TwoFactorAuthentication>> {
    return ApiService.get(`${apiPath}/userTwoFactorAuthentications`);
  }

  public static async addTwoFactorAuthentication(
    twoFactorAuthentication: AddTwoFactorAuthenticationRequest
  ): Promise<string> {
    return ApiService.post(`${apiPath}/userTwoFactorAuthentications`, {
      body: twoFactorAuthentication,
    }).then((id: string) => id);
  }

  public static async updateTwoFactorAuthentication(
    id: string,
    model: TwoFactorStatusSettingsBindingModel
  ): Promise<string> {
    return ApiService.put(`${apiPath}/userTwoFactorAuthentications/${id}`, {
      body: model,
    });
  }

  public static async setDefaultTwoFactorAuthentication(
    id: string
  ): Promise<TwoFactorAuthentication> {
    return ApiService.put(`${apiPath}/userTwoFactorAuthentications/${id}/default`);
  }

  public static async generateSmsCode(settings: SmsSettings): Promise<GenerateSmsCodeResponse> {
    return ApiService.post(`${apiPath}/userTwoFactorAuthentications/setups/sms`, {
      body: settings,
    });
  }

  static async sendDisableCode(
    twoFactorId: string,
    request: SendDisableCodeRequest
  ): Promise<void> {
    return ApiService.post(
      `${apiPath}/userTwoFactorAuthentications/${twoFactorId}/sendDisableCode`,
      {
        body: request,
      }
    );
  }

  public static async generateOtpCode(): Promise<GenerateOtpCodeResponse> {
    return ApiService.post(`${apiPath}/userTwoFactorAuthentications/setups/otp`);
  }

  /**
   * Authenicates against the two factor challenge.
   * @param binding The challenge response.
   * @param twoFactorAuthenticationId The ID of the MFA.
   * @returns {AuthenticateResponse} Authentication response.
   */
  public static async authenticate(
    twoFactorAuthenticationId: string,
    binding: TwoFactorStatusSettingsBindingRequest
  ): Promise<AuthenticateResponse> {
    return ApiService.post<AuthenticateResponse>(
      `${apiPath}/userTwoFactorAuthentications/${twoFactorAuthenticationId}/authenticate`,
      { body: binding }
    );
  }

  /**
   * Initiates the login flow for SMS.
   * @param twoFactorAuthenticationId The ID of the MFA.
   * @returns {boolean} Boolean value indicating whether authentication has been started or not.
   */
  public static async beginAuthentication(twoFactorAuthenticationId: string): Promise<boolean> {
    return ApiService.post<boolean>(
      `${apiPath}/userTwoFactorAuthentications/${twoFactorAuthenticationId}/beginAuthentication`
    );
  }

  /**
   * Initiates the login flow for SMS by calling the user.
   * @param twoFactorAuthenticationId The ID of the MFA.
   * @returns {boolean} Boolean value indicating whether authentication has been started or not.
   */
  static async beginCallAuthentication(twoFactorAuthenticationId: string): Promise<boolean> {
    return ApiService.post<boolean>(
      `${apiPath}/userTwoFactorAuthentications/${twoFactorAuthenticationId}/beginCallAuthentication`
    );
  }
}

export {
  EventType,
  TwoFactorAuthenticationType,
  TwoFactorAuthentication,
  TwoFactorAuthenticationStatus,
  UserSessionTwoFactorStatus,
};
export default TwoFactorAuthenticationService;
