//
// When browser translation is used to localize our app by end users (e.g. automatically by Chrome),
// React explodes. React have decided not to fix this issue due to the performance impact it would
// cause unnecessarily in most instances. We will detect translation schemes by Chrome, Safari, and
// Edge, and monkey-patch the DOM to fix the issue so that users get a functional, albeit slightly
// slower, Plooto experience.
//
// See: https://github.com/facebook/react/issues/11538#issuecomment-417504600
//
// See: https://bugs.chromium.org/p/chromium/issues/detail?id=872770
//

let installed = false;

function installHack(scheme: string) {
  //
  // This patch is taken verbatim from the React team and is not subject to our code style.
  //
  // See: https://github.com/facebook/react/issues/11538#issuecomment-417504600
  //

  if (installed) {
    return;
  }

  installed = true;

  /* eslint-disable */
  console.warn(
    `⚠️ Page translation has been detected (${scheme}). This is not supported. A patch has been ` +
      'applied to Node#removeChild and Node#insertBefore to mitigate the issues page translation ' +
      'creates.'
  );

  if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    Node.prototype.removeChild = function (child) {
      if (child.parentNode !== this) {
        if (console) {
          console.error('Cannot remove a child from a different parent', child, this);
        }
        return child;
      }
      return originalRemoveChild.apply(this, arguments);
    };

    const originalInsertBefore = Node.prototype.insertBefore;
    Node.prototype.insertBefore = function (newNode, referenceNode) {
      if (referenceNode && referenceNode.parentNode !== this) {
        if (console) {
          console.error(
            'Cannot insert before a reference node from a different parent',
            referenceNode,
            this
          );
        }
        return newNode;
      }
      return originalInsertBefore.apply(this, arguments);
    };
  }
  /* eslint-enable */
}

function installForChromeSafari() {
  function tryInstallHack(html: HTMLHtmlElement) {
    // Google Translate does a bunch of stuff, but notably, it changes the lang attribute on <html>.
    // We hard-code this to "en" [1], so if the value is different from this, then some tomfoolery
    // is afoot (probably by $GOOG).
    //
    // [1]: src/index.hbs
    if (html.getAttribute('lang') !== 'en') {
      installHack('Chrome/Safari');
      return true;
    }

    return false;
  }

  const [targetNode] = document.getElementsByTagName('html');

  if (tryInstallHack(targetNode)) {
    return;
  }

  const config = { attributes: true };
  const observer = new MutationObserver((mutationList) => {
    for (const mutation of mutationList) {
      if (mutation.type === 'attributes' && mutation.attributeName === 'lang') {
        if (tryInstallHack(targetNode)) {
          observer.disconnect();
        }
      }
    }
  });
  observer.observe(targetNode, config);
}

// Edge uses this attribute name to store some data.
// eslint-disable-next-line @cspell/spellchecker
const EdgeAttributeName = '_msttexthash';

function installForEdge() {
  function tryInstallHack(html: HTMLTitleElement) {
    // Edge Translate does a bunch of stuff, but notably, it adds a _msttexthash attribute to
    // <title>. We never use this attribute, so if we see it, assume tomfoolery is afoot (certainly
    // by $MSFT).
    if (html.hasAttribute(EdgeAttributeName)) {
      installHack('Edge');
      return true;
    }

    return false;
  }

  const [targetNode] = document.getElementsByTagName('title');

  if (tryInstallHack(targetNode)) {
    return;
  }

  const config = { attributes: true };
  const observer = new MutationObserver((mutationList) => {
    for (const mutation of mutationList) {
      if (mutation.type === 'attributes' && mutation.attributeName === EdgeAttributeName) {
        if (tryInstallHack(targetNode)) {
          observer.disconnect();
        }
      }
    }
  });
  observer.observe(targetNode, config);
}

/** Conditionally install a work-around for automatic translation if it is detected on the page. */
export default function installAutomaticTranslationHack() {
  installForChromeSafari();
  installForEdge();
}
