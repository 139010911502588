export enum LegacyFeatureFlag {
  FEATURE_DASHBOARD_WIZARD = 'FEATURE_DASHBOARD_WIZARD',
  FEATURE_PAYMENT_REQUESTS_CREDIT_CARDS = 'FEATURE_PAYMENT_REQUESTS_CREDIT_CARDS',
  FEATURE_PLOOTO_INSTANT_BANK_FEEDS = 'FEATURE_PLOOTO_INSTANT_BANK_FEEDS',
  FEATURE_RETAIN_FORM_DATA = 'FEATURE_RETAIN_FORM_DATA',
  FEATURE_SEND_REMINDER_EMAIL_TO_APPROVERS = 'FEATURE_SEND_REMINDER_EMAIL_TO_APPROVERS',
  LIMITED_DOMESTIC_PAYMENTS_CAPABILITY_ONBOARDING = 'LIMITED_DOMESTIC_PAYMENTS_CAPABILITY_ONBOARDING',
  ONBOARDING_WIZARD = 'ONBOARDING_WIZARD',
  SECONDARY_BANK_LOGIN = 'SECONDARY_BANK_LOGIN',
}
