import { memoize } from 'lodash-es';

import { SupportedLocaleType } from '../config';

import localeOrDefault from './localeOrDefault';

/** @private */
const getMessagesImpl = memoize(
  async (locale: SupportedLocaleType): Promise<Record<string, string>> => {
    const url = new URL(`/locales/${locale}.json`, window.location.origin);

    // This is a request for a static app resource, and therefore exempt from the fetch ban, which
    // is intended for requests against the BE API.
    // eslint-disable-next-line no-restricted-syntax
    const response = await fetch(url.href, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    const data = await response.json();

    return data;
  }
);

export const messagesCache = getMessagesImpl.cache;

/**
 * Retrieves i18n messages/translations for a specified locale, using `en-CA` as the fallback locale.
 *
 * The function uses a cache to avoid fetching the same messages multiple times, especially across instances of `PlootoPlatformProvider`.
 *
 * @param locale Locale for which to retrieve messages/translations.
 * @returns Messages/translations for the specified locale.
 */
export default function getMessages(locale: string) {
  const localeWithFallback = localeOrDefault(locale);
  return getMessagesImpl(localeWithFallback);
}
