import React from 'react';
import { IntlProvider } from 'react-intl';
import CircularProgress from '@mui/material/CircularProgress';
import { useQuery } from '@tanstack/react-query';

import { FALLBACK_LOCALE, SupportedLocaleType } from '../config';
import getMessages from '../utils/getMessages';

type I18nProviderProps = {
  locale: SupportedLocaleType;
  children: React.ReactNode;
};

/**
 * Provides the i18n messages to the application.
 *
 * !!!: This Provider requires TanStack QueryClientProvider to be provided in order to work.
 *
 * @param props {@link I18nProviderProps}
 */
const I18nProvider = ({ locale, children }: I18nProviderProps) => {
  const { data: messages } = useQuery({
    queryKey: ['i18n-messages', locale],
    queryFn: () => getMessages(locale),
  });

  if (!messages) {
    return <CircularProgress />;
  }

  return (
    <IntlProvider locale={locale} defaultLocale={FALLBACK_LOCALE} messages={messages}>
      {children}
    </IntlProvider>
  );
};

export type { I18nProviderProps };
export default I18nProvider;
