import * as segmentAnalytics from 'segmentAnalytics';

import CompanyEventService from '@/legacy/services/companyEventService';

import { FormEventType } from '../../../analytics/helper';

import {
  SubscriptionContext,
  SubscriptionEventButtonName,
  SubscriptionEventDiscriminator,
} from './helper';

/**
 * Analytics events listener which triggers Segment Analytics
 * tracking for subscription plan
 * This gets called on registerAnalyticsEventsListeners.ts and registers event handlers
 */
const analyticsEventsListener = {
  bind(): void {
    CompanyEventService.on(
      SubscriptionEventDiscriminator.CancelSubscription,
      (payload: CustomEvent) => {
        segmentAnalytics.track(FormEventType.ButtonClicked, {
          buttonName: SubscriptionEventButtonName.CancelSubscription,
          context: SubscriptionContext.BillingAndSubscription,
          ...payload?.detail,
        });
      }
    );

    CompanyEventService.on(
      SubscriptionEventDiscriminator.SelectUpgradeSubscription,
      (payload: CustomEvent) => {
        segmentAnalytics.track(FormEventType.ButtonClicked, {
          buttonName: SubscriptionEventButtonName.UpgradeSubscriptionPlan,
          context: SubscriptionContext.BillingAndSubscription,
          ...payload?.detail,
        });
      }
    );

    CompanyEventService.on(
      SubscriptionEventDiscriminator.SelectDowngradeSubscription,
      (payload: CustomEvent) => {
        segmentAnalytics.track(FormEventType.ButtonClicked, {
          buttonName: SubscriptionEventButtonName.DowngradeSubscriptionPlan,
          context: SubscriptionContext.BillingAndSubscription,
          ...payload?.detail,
        });
      }
    );

    CompanyEventService.on(
      SubscriptionEventDiscriminator.CancelDowngrade,
      (payload: CustomEvent) => {
        segmentAnalytics.track(FormEventType.ButtonClicked, {
          buttonName: SubscriptionEventButtonName.CancelDowngrade,
          context: SubscriptionContext.BillingAndSubscription,
          ...payload?.detail,
        });
      }
    );

    CompanyEventService.on(SubscriptionEventDiscriminator.CancelUpgrade, (payload: CustomEvent) => {
      segmentAnalytics.track(FormEventType.ButtonClicked, {
        buttonName: SubscriptionEventButtonName.CancelUpgrade,
        context: SubscriptionContext.BillingAndSubscription,
        ...payload?.detail,
      });
    });

    CompanyEventService.on(
      SubscriptionEventDiscriminator.DismissUpgrade,
      (payload: CustomEvent) => {
        segmentAnalytics.track(FormEventType.ButtonClicked, {
          buttonName: SubscriptionEventButtonName.DismissUpgrade,
          context: SubscriptionContext.UpgradeSubscription,
          ...payload?.detail,
        });
      }
    );

    CompanyEventService.on(
      SubscriptionEventDiscriminator.ConfirmUpgrade,
      (payload: CustomEvent) => {
        segmentAnalytics.track(FormEventType.ButtonClicked, {
          buttonName: SubscriptionEventButtonName.ConfirmUpgrade,
          context: SubscriptionContext.UpgradeSubscription,
          ...payload?.detail,
        });
      }
    );

    CompanyEventService.on(
      SubscriptionEventDiscriminator.DismissDowngrade,
      (payload: CustomEvent) => {
        segmentAnalytics.track(FormEventType.ButtonClicked, {
          buttonName: SubscriptionEventButtonName.DismissDowngrade,
          context: SubscriptionContext.DowngradeSubscription,
          ...payload?.detail,
        });
      }
    );

    CompanyEventService.on(
      SubscriptionEventDiscriminator.ConfirmDowngrade,
      (payload: CustomEvent) => {
        segmentAnalytics.track(FormEventType.ButtonClicked, {
          buttonName: SubscriptionEventButtonName.ConfirmDowngrade,
          context: SubscriptionContext.DowngradeSubscription,
          ...payload?.detail,
        });
      }
    );

    CompanyEventService.on(
      SubscriptionEventDiscriminator.ConfirmCancelDowngrade,
      (payload: CustomEvent) => {
        segmentAnalytics.track(FormEventType.ButtonClicked, {
          buttonName: SubscriptionEventButtonName.CancelDowngrade,
          context: SubscriptionContext.CancelDowngrade,
          ...payload?.detail,
        });
      }
    );

    CompanyEventService.on(
      SubscriptionEventDiscriminator.ConfirmCancelUpgrade,
      (payload: CustomEvent) => {
        segmentAnalytics.track(FormEventType.ButtonClicked, {
          buttonName: SubscriptionEventButtonName.CancelUpgrade,
          context: SubscriptionContext.CancelUpgrade,
          ...payload?.detail,
        });
      }
    );

    CompanyEventService.on(
      SubscriptionEventDiscriminator.DismissCancelDowngrade,
      (payload: CustomEvent) => {
        segmentAnalytics.track(FormEventType.ButtonClicked, {
          buttonName: SubscriptionEventButtonName.DismissCancelDowngrade,
          context: SubscriptionContext.CancelDowngrade,
          ...payload?.detail,
        });
      }
    );

    CompanyEventService.on(
      SubscriptionEventDiscriminator.DismissCancelUpgrade,
      (payload: CustomEvent) => {
        segmentAnalytics.track(FormEventType.ButtonClicked, {
          buttonName: SubscriptionEventButtonName.DismissCancelUpgrade,
          context: SubscriptionContext.CancelUpgrade,
          ...payload?.detail,
        });
      }
    );

    CompanyEventService.on(
      SubscriptionEventDiscriminator.ConfirmCancelSubscription,
      (payload: CustomEvent) => {
        segmentAnalytics.track(FormEventType.ButtonClicked, {
          buttonName: SubscriptionEventButtonName.ConfirmCancelSubscription,
          context: SubscriptionContext.CancelPlootoMonthlySubscription,
          ...payload?.detail,
        });
      }
    );

    CompanyEventService.on(
      SubscriptionEventDiscriminator.SubscriptionCancellationReason,
      (payload: CustomEvent) => {
        segmentAnalytics.track(FormEventType.ButtonClicked, {
          buttonName: SubscriptionEventButtonName.SubscriptionCancellationReason,
          context: SubscriptionContext.CancelPlootoMonthlySubscription,
          ...payload?.detail,
        });
      }
    );

    CompanyEventService.on(
      SubscriptionEventDiscriminator.RemainSubscribed,
      (payload: CustomEvent) => {
        segmentAnalytics.track(FormEventType.ButtonClicked, {
          buttonName: SubscriptionEventButtonName.RemainSubscribed,
          context: SubscriptionContext.CancelPlootoMonthlySubscription,
          ...payload?.detail,
        });
      }
    );

    CompanyEventService.on(
      SubscriptionEventDiscriminator.RemainSubscribed,
      (payload: CustomEvent) => {
        segmentAnalytics.track(FormEventType.ButtonClicked, {
          buttonName: SubscriptionEventButtonName.RemainSubscribed,
          context: SubscriptionContext.CancelPlootoMonthlySubscription,
          ...payload?.detail,
        });
      }
    );
  },
};

export default analyticsEventsListener;
