import { LocaleObject, setLocale } from 'yup';
// This dependency is all compiled together, so there's no real opportunity to lazy-load only the
// appropriate locale without relying on tree-shaking and module duplication, which Webpack will
// probably never because the impact is too small.
import { en } from 'yup-locales';

import { SupportedLocale } from '../config';

import userPreferredLocale from './userPreferredLocale';

export const installYupLocale = () => {
  let localeObject: LocaleObject;
  switch (userPreferredLocale) {
    case SupportedLocale.EnCA:
    default:
      localeObject = en;
      break;
  }
  setLocale(localeObject);
};
