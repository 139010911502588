/**
 * Import-meta-env, for whatever reason, strips the opening quote from the first item in the array, and the closing quote from the last item in the array when the dotenv variable is a simple comma-separated string array (no square brackets; e.g., "a', 'b', 'c"), so we need to handle that case.
 *
 * @example
 * restoreFirstAndLastItemQuotes("a', 'b', 'c") //=> "'a', 'b', 'c'"
 */
const restoreFirstAndLastItemQuotes = (value: string) => {
  const splitValues = value.split(',');

  const firstValue = splitValues[0].trim();
  if (firstValue.endsWith("'") && !firstValue.startsWith("'")) {
    splitValues[0] = `'${firstValue}`;
  }

  const lastValue = splitValues[splitValues.length - 1].trim();
  if (lastValue.startsWith("'") && !lastValue.endsWith("'")) {
    splitValues[splitValues.length - 1] = `${lastValue}'`;
  }

  return splitValues.join(',');
};

/**
 * Convert single-quoted comma-separated string to double-quoted comma-separated string
 *
 * Required for making dotenv array variable values valid JSON strings.
 *
 * @param value Comma-separated variable value
 * @returns Single-quoted comma-separated string converted to double-quoted comma-separated string
 * @private
 */
const convertSingleQuoteArrayToDoubleQuoteArray = (value) =>
  value
    .split(',')
    .map((item) => item.trim())
    .map((item) =>
      item.startsWith("'") && item.endsWith("'") ? `"${item.substring(1, item.length - 1)}"` : item
    )
    .filter(Boolean);

/**
 * Normalize comma-separated string or stringified array
 * @param value Comma-separated variable value
 * @returns Normalized comma-separated string
 * @private
 */
const normalizeCommaSeparatedArrayString = (value) => {
  const valueStrippedOfSquareBrackets = value.replace(/^\[/, '').replace(/\]$/, '');
  const withFirstAndLastItemQuotesRestored = restoreFirstAndLastItemQuotes(
    valueStrippedOfSquareBrackets
  );
  const doubleQuoteArrayString = convertSingleQuoteArrayToDoubleQuoteArray(
    withFirstAndLastItemQuotesRestored
  );
  return `[${doubleQuoteArrayString}]`;
};

/**
 * Parse comma-separated string to array
 *
 * Useful for parsing dotenv array variables that aren't JSON-parseable.
 *
 * @param value Comma-separated variable value
 * @returns Parsed array
 *
 * @example
 * parseStringArray("'a','b','c'") // ['a', 'b', 'c']
 * parseStringArray("['a','b','c']") // ['a', 'b', 'c']
 * parseStringArray("1,2,3") // [1, 2, 3]
 * parseStringArray("true,false,true") // [true, false, true]
 * parseStringArray("'a', 2, true, null") // ['a', 2, true, null]
 */
const parseStringArray = (value) => {
  if (value === 'null') {
    return null;
  }
  if (!value || typeof value !== 'string') {
    return [];
  }

  const normalizedValue = normalizeCommaSeparatedArrayString(value);
  return JSON.parse(normalizedValue);
};

export default parseStringArray;
