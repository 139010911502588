// Need to force a web build but don't want to make a change? Increment this integer! (1)
import { createElement } from 'react';
import { createRoot } from 'react-dom/client';
import app from 'durandal/app';
import binder from 'durandal/binder';
import system from 'durandal/system';
import viewLocator from 'durandal/viewLocator';
import dialog from 'plugins/dialog';
import router from 'plugins/router';
import ko from 'knockout';
import knockoutMapping from 'knockout-mapping';
import { LicenseInfo } from '@mui/x-license';
import $ from 'jquery';
import * as segmentAnalytics from 'segmentAnalytics';
import yn from 'yn';

import '@/vendor/durandal/overrides/system';
import '@/vendor/durandal/overrides/views';
import '@/vendor/durandal/overrides/modals';
import '@/vendor/durandal/overrides/module-declarations';

import '@/vendor/googleAnalyticsFallback';
// jQuery plugins
import 'bootstrap';
import 'jquery.storageapi';
import 'jquery.cookie';
import '../Content/lib/knockout/js/knockout.custom.bindings';

import { Shell } from '@/components/Shell';
import { APP_URL, DURANDAL_LOG_ERRORS } from '@/config/env';
import isProductionEnv from '@/config/helpers/isProductionEnv';
import { FeatureToggleService } from '@/features/feature-flags';
import { installYupLocale } from '@/features/i18n';
import initializeAppEvents from '@/legacy/events';
import { initializePendoListeners } from '@/legacy/pendo';
import addReferenceElement from '@/utils/addReferenceElement';
import consolePrintLogo from '@/utils/consolePrintLogo';
import installAutomaticTranslationHack from '@/utils/installAutomaticTranslationHack';
import showEnvironment from '@/utils/showEnvironment';
import durandalReact from '@/vendor/durandalReact';

import analyticsEventsListener from './features/analytics/analyticsEventsListener';
import registerAnalyticsEventsListeners from './features/analytics/registerAnalyticsEventsListeners';
import errorTrackingService from './services/errorTrackingService';
import * as externalAuthenticationService from './services/externalAuthenticationService';
import * as oAuthService from './services/oAuthService';
import * as userService from './services/user';

// TODO: Remove/migrate legacy Less files
import '@/Content/css/plooto.less';
import '@/Content/sass/main.scss';

declareModules({
  'viewmodels/shell': () => import('@/legacy/viewmodels/shell'),
  'views/shell.html': () => import('@/legacy/views/shell.html'),
});

declare let __DEV__: boolean;
declare global {
  interface Window {
    ko: KnockoutStatic;
    app: DurandalAppModule;
    router: DurandalRootRouter;
  }
}

// Webpack sets this __DEV__ variable. See `webpack.config.js` file
if (__DEV__) {
  system.debug(true);

  window.ko = ko;
  window.app = app;
  window.router = router;
}

// Add `ko.mapping` plugin
ko.mapping = knockoutMapping;

if (console && console.log) {
  consolePrintLogo();
}

if (yn(import.meta.env.APP_SHOW_ENVIRONMENT)) {
  showEnvironment(import.meta.env.APP_ENVIRONMENT_LABEL);
}

// Add reference elements to the DOM
addReferenceElement(
  'head',
  'script',
  `${import.meta.env.FEATURE_SALESFORCE_DEPLOYMENT_URL}/deployment.js`
);
addReferenceElement(
  'head',
  'script',
  // String holds data, not user-displayed text.
  // eslint-disable-next-line @cspell/spellchecker
  `${import.meta.env.FEATURE_SALESFORCE_PRE_CHAT_URL}/prechat.js`
);
addReferenceElement(
  'body',
  'script',
  `https://www.googleoptimize.com/optimize.js?id=${import.meta.env.GOOGLE_TAG_MANAGER_TRACKING_ID}`
);

// by default send over the cookies/credentials to the server. so we dont have to specify for every
// request manually
$.ajaxSetup({
  xhrFields: {
    withCredentials: true,
  },
});

// Fix the white space on content wrappers (these should take up the space of the entire window)
const resizeModalsCallback = () => {
  let minHeight =
    $(window).height() - $('.navbar-fixed-bottom').height() - $('.navbar-fixed-top').height();

  // Adjust modal inside another modal wrapper (So the top most modal covers the one under it)
  $.each($('.modalHost').children(), (index, modalHostChild) => {
    if ($(modalHostChild).height() > minHeight) {
      minHeight = $(modalHostChild).height();
    }
  });

  $('.plooto-content-wrapper').css('min-height', minHeight);
};
$(window).on('resize', () => {
  resizeModalsCallback();
});
resizeModalsCallback();

const nua = navigator.userAgent;
const isAndroid =
  nua.indexOf('Mozilla/5.0') > -1 &&
  nua.indexOf('Android ') > -1 &&
  nua.indexOf('AppleWebKit') > -1 &&
  nua.indexOf('Chrome') === -1;
if (nua.match(/IEMobile\/10\.0/)) {
  const msViewportStyle = document.createElement('style');
  msViewportStyle.appendChild(document.createTextNode('@-ms-viewport{width:auto!important}'));
  document.getElementsByTagName('head')[0].appendChild(msViewportStyle);
}

router.on('router:navigation:complete', (instance, instruction, routerContext) => {
  app.trigger('router:navigation:complete', instance, instruction, routerContext);
  routerContext.activeInstruction();

  // A duplicate copy of this pageId computation exists inside segment\analytics.js
  let pageId = window.location.hash;
  if (pageId.length > 1 && pageId.substr(0, 1) === '#') {
    pageId = pageId.substr(1);
  }
  // anonymize URL
  pageId = pageId.replace(/[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}/gi, '<id>');
  pageId = pageId.replace(/[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}/gi, '<email>');
  // additional url stripping for openid related urls
  pageId = pageId.replace(
    /(openid\.intuit\.com%252FIdentity-)([0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12})(\/)([0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12})/gi,
    '$1<id>$3<id>'
  );

  // path property/trait will be injected inside the page() function
  segmentAnalytics.page('Page View', {
    title: document.title,
    referrer: document.referrer,
  });

  // Track page view when route is completed
  window.appInsights.trackPageView(document.title, pageId);
});

$(document).on('dragstart', (e) => {
  e.preventDefault();
  return false;
});

$.support.cors = true;
system.debug(DURANDAL_LOG_ERRORS);
app.title = 'Plooto';

// Install Durandal plugins
router.install();
dialog.install({});

/**
 * Using `.default.bind()` here because of Require.js and default exports.
 * In places where JS imports work, it would be:
 * `registerAnalyticsEventsListeners.bind();`
 */
registerAnalyticsEventsListeners.bind();
analyticsEventsListener.bind();
errorTrackingService.bind();

initializeAppEvents();
initializePendoListeners();

// Install the MUI Commercial License
// See: https://mui.com/x/introduction/licensing/#license-key
LicenseInfo.setLicenseKey(import.meta.env.MUI_LICENSE_KEY);

const reactRoot = createRoot(document.getElementById('react-root'));

app.start().then(() => {
  // replace 'viewmodels' in the moduleId with 'views' to locate the view.
  // look for partial views in a 'views' folder in the root.
  viewLocator.useConvention();

  durandalReact.initialize();

  if (!isProductionEnv) {
    // @ts-expect-error Console command for debugging.
    window.PlootoPrintFeatureFlags = () => {
      FeatureToggleService.logAllFeatureFlags();
    };
  }

  // call localization on view before binding...
  binder.binding = function (obj, view) {
    $(view)
      .find('input[type="text"],input[type="email"]')
      .blur((e) => {
        const $element = $(e.target);
        $element.val($element.val().trim());
        $element.attr('value', $element.val().trim());
      });

    $(view)
      .find('button,a')
      .click((e) => {
        const $target = $(e.target);
        let label = $target.val();
        if (label == '') {
          label = $target.text();
        }
        label = $.trim(label);

        window.dataLayer.push({
          event: 'GAevent',
          eventCategory: 'action',
          eventAction: 'click',
          eventLabel: label,
        });

        // Track button clicks
        window.appInsights.trackEvent('buttonClick', { label });
      });
    $(view)
      .find('input[type="text"]')
      .focusout((e) => {
        const $target = $(e.target);
        let name = $target.attr('name');
        name = $.trim(name);
        const value = $.trim($target.val());

        window.dataLayer.push({
          event: 'GAevent',
          eventCategory: 'action',
          eventAction: 'fill',
          eventLabel: name,
          eventEmpty: value == '',
        });
      });
    setTimeout(() => {
      $(view).find('.autofocus').first().focus().select();
    }, 1200);
  };

  // occurs after ko binding completes
  binder.bindingComplete = function (data, view, instruction) {
    // Assign id and class name for durandal component
    if (!$(view).prop('id')) {
      const durandalModuleName = data.__moduleId__.replace(/\//g, '-');
      $(view).prop('id', durandalModuleName);
    }

    if (window.optimizely) {
      const experiments = window.optimizely.allExperiments;
      if (experiments) {
        $.each(experiments, (experimentIndex, experiment) => {
          if (!experiment.enabled) {
            return;
          }
          if (experiment.activation_mode != 'manual') {
            return;
          }

          $.each(experiment.urls, (urlIndex, url) => {
            if (url.match != 'simple') {
              return;
            }
            if (url.value == window.location.href) {
              window.optimizely.push(['activate', experimentIndex]);
            }
          });
        });
      }
    }
    // addresses bootstrap bug with native android browser
    if (isAndroid) {
      $('select.form-control').removeClass('form-control').css('width', '100%');
    }
  };

  // Trigger Google Optimize experience with optimize.activate custom event
  window.dataLayer = window.dataLayer || [];
  if (MutationObserver) {
    const mutationObserver = new MutationObserver(() => {
      window.dataLayer.push({ event: 'optimize.activate' });
      mutationObserver.takeRecords();
    });
    mutationObserver.observe(document.body, {
      subtree: true,
      childList: true,
      attributes: true,
      characterData: true,
    });
  }

  // for sites hosted on base url or outside, we jump right into resume
  if (
    window.location.host != 'app.plooto.com' &&
    window.location.hash === '' &&
    `${window.location.protocol}//${window.location.host}` != APP_URL &&
    window.location.host.indexOf('plooto.com') != -1 &&
    window.location.host != 'plooto-staging-front-end.plooto.com'
  ) {
    const domain = window.location.host.substr(0, window.location.host.indexOf('.'));
    Promise.resolve(externalAuthenticationService.Instance.GetDomainVerification(domain))
      .then(() => oAuthService.Instance.Authenticate(domain))
      .then(
        (oAuthUrl) => {
          // TODO: for now we assume domain verification is always done via OAuth
          window.location.href = oAuthUrl;
        },
        () => {
          window.location.href = APP_URL;
        }
      );

    // attempt to get available authentication service for a domain
  } else {
    userService.Instance.ResumeSession()
      .always(() => {
        app.setRoot('viewmodels/shell', 'basic');
        reactRoot.render(createElement(Shell));
      })
      .then(() => {
        if (window.location.hash === '') {
          window.location.href = '#user';
        }
      });
  }
});

// Asynchronously install this hack so that any failure does not halt script execution.
setTimeout(installAutomaticTranslationHack, 0);

// Asynchronously install localized validation messages for `yup`. These won't show on page load so
// there should be no race condition. If this assumption changes, instead install these in
// I18nProvider like FormatJS.
setTimeout(installYupLocale, 0);
