import * as system from 'durandal/system';

type BaseModalOptions = Record<string, unknown> & {
  view?: string;
};

/**
 * Base class for Durandal modals in Webpack build environment.
 *
 * Because ESM module imports do not use Durandal's `system#acquire` method,
 * this class provides an interface for manually setting a view path, which is required
 * to load the view HTML layer of the modal.
 */
class BaseModal {
  options: BaseModalOptions;

  /** Durandal view path */
  protected view: string;

  constructor(options: BaseModalOptions = {}) {
    this.options = options;
    this.view = options.view;
  }

  // Override the default `getView` logic that Durandal utilises to
  // fetch the view for each ViewModel instance.
  getView() {
    system.setModuleId(this, this.view);
    return this.view;
  }
}

export default BaseModal;
