import * as app from 'durandal/app';
import * as system from 'durandal/system';
import * as ko from 'knockout';

import { LegacyFeatureFlag } from '@/features/feature-flags/types/LegacyFeatureFlag';
import negativeBalanceModal from '@/legacy/features/companies/prefundedWallet/modals/negativeBalanceModal';
import EventsService from '@/legacy/services/eventsService';
import RealtimeEvent from '@/legacy/services/realtime/models/realtimeEvent';
import RealtimeService from '@/legacy/services/realtime/realtimeService';
import * as userService from '@/legacy/services/user';
import * as verificationService from '@/legacy/services/verification/verification';
import * as plootoUtils from '@/legacy/utils/plooto';

const API_PREFIX = 'v1';

const LAST_COMPANY_STORAGE_KEY = '_lastCompany';

declare const liveagent: any;

export enum PermissionId {
  BANK_ACCOUNT_ADD_EDIT,
  APPROVAL_POLICY_ADD_EDIT,

  PAYEE_ADD_EDIT,

  PAYMENT_APPROVE,
  PAYMENT_ADD_EDIT,
  DOCUMENT_PUBLISH,

  USER_ADD_EDIT,

  COMPANY_EDIT,
}

export enum PermissionNames {
  BANK_ACCOUNT_ADD_EDIT = 'bank_account_add_edit',
  APPROVAL_POLICY_ADD_EDIT = 'approval_policy_add_edit',
  PAYEE_ADD_EDIT = 'payee_add_edit',
  PAYMENT_APPROVE = 'payment_approve',
  PAYMENT_ADD_EDIT = 'payment_add_edit',
  USER_ADD_EDIT = 'user_add_edit',
  COMPANY_EDIT = 'company_edit',
  DOCUMENT_PUBLISH = 'document_publish',
}

export enum PermissionType {
  admin,
  cfo,
  accountant,
  viewer,
  custom,
}

export enum ImageSize {
  Original = 0,
  EmailHeader = 1,
}

export class CompanyService {
  private userService: userService.UserService;

  private verificationService: verificationService.VerificationService;

  private lastCompanyStorage: JQueryStorageStatic;

  private selectedCompanyIndex: KnockoutObservable<number> = ko.observable(undefined);

  public membership: KnockoutComputed<userService.Membership> = ko.computed(() => {
    const index = this.selectedCompanyIndex();
    if (index === undefined) {
      return undefined;
    }
    if (index >= this.userService.memberships().length) {
      return undefined;
    }
    return this.userService.memberships()[index];
  });

  public membershipPermissions: KnockoutComputed<Array<string>> = ko.pureComputed(
    () => this.membership()?.permissions() ?? []
  );

  private updateNotificationpSubscription: DurandalEventSubscription;

  private membershipSubscription: KnockoutSubscription = undefined;

  public profile: KnockoutComputed<userService.CompanyProfile> = ko.computed({
    read: () => {
      if (this.membership() === undefined) {
        return undefined;
      }
      return this.membership().company();
    },
    deferEvaluation: true,
  });

  public status: KnockoutObservable<string> = ko.observable('loading');

  constructor() {
    this.userService = userService.Instance;
    this.lastCompanyStorage = $.localStorage;
    this.verificationService = verificationService.Instance;

    this.membershipSubscription = this.userService.memberships.subscribe(() => {
      // no selection has been made yet, bail
      if (this.CompanyId() == undefined) {
        return;
      }
      // something changed in membership, attempt to re-select it or select another one
      this.SelectCompany(this.CompanyId());
    });
  }

  public LoadCompanyProfile(companyId): JQueryPromise<any> {
    return system
      .defer((dfd) => {
        // TODO: Error handling
        this.userService
          .AuthorizedGet(
            `${import.meta.env.APP_URLS_API_URL}/${API_PREFIX}/companies/${companyId}/profile`
          )
          .done((profileData) => {
            if (plootoUtils.IsNullOrUndefined(profileData) || profileData.error === true) {
              dfd.reject(profileData);
              return;
            }
            ko.mapping.fromJS(profileData, {}, this.profile);

            app.trigger('company:profile:updated');

            dfd.resolve();
          })
          .fail(() => {
            dfd.reject();
          });
      })
      .promise();
  }

  public SetCompanyProfile(profileData) {
    ko.mapping.fromJS(profileData, {}, this.profile);
  }

  public LoadCompanyCapabilities(companyId): JQueryPromise<any> {
    return system.defer((dfd) => {
      // (empty)
    });
  }

  public IsCompanySelected: KnockoutComputed<boolean> = ko.computed({
    read: () => {
      const retVal: boolean = this.membership() !== undefined;
      return retVal;
    },
    deferEvaluation: true,
  });

  public isPartnership: KnockoutComputed<boolean> = ko.computed({
    read: () => {
      const corporationType = this.profile().corporationType();
      return corporationType === 'Partnership (GP, LP, LLP)';
    },
    deferEvaluation: true,
  });

  public isSoleProprietorship: KnockoutComputed<boolean> = ko.computed({
    read: () => this.profile()?.corporationType() === 'Sole Proprietorship',
    deferEvaluation: true,
  });

  public IsAdministrator(): boolean {
    if (this.membership() === undefined) {
      return false;
    }
    return this.membership().role() == 'admin';
  }

  public HasPermissionInAnyMembershipTo(id: PermissionId): boolean {
    return this.userService.memberships().some((m) => this.MembershipHasPermissionTo(m, id));
  }

  // For checking if the currently selected company membership has a permission to do something
  public HasPermissionTo(id: PermissionId | string): boolean {
    return this.MembershipHasPermissionTo(this.membership(), id);
  }

  // For checking if the accounting firm assigned to to this company has a permission to do something (This will check the user's permissions in the accounting firm)
  public HasPermissionInAccountingFirmTo(id: PermissionId): boolean {
    const accountingFirmMembership = this.getAccountingFirmMembership();
    return this.MembershipHasPermissionTo(accountingFirmMembership, id);
  }

  public HasAllPermissions(): boolean {
    const membership = this.membership();

    if (membership === undefined) {
      return false;
    }

    if (!ko.isObservable(membership.permissions)) {
      return false;
    }

    const userPermissions = membership.permissions();

    let bankAccountPermission = false;
    let approvalPolicyPermission = false;
    let payeeAddEditPermission = false;
    let paymentAddEditPermission = false;
    let userAddEditPermission = false;
    let companyEditPermission = false;
    let documentPublishPermission = false;

    userPermissions.forEach((permission) => {
      switch (permission) {
        case 'bank_account_add_edit':
          bankAccountPermission = true;
          break;
        case 'approval_policy_add_edit':
          approvalPolicyPermission = true;
          break;
        case 'payee_add_edit':
          payeeAddEditPermission = true;
          break;
        case 'payment_add_edit':
          paymentAddEditPermission = true;
          break;
        case 'document_publish':
          documentPublishPermission = true;
          break;
        case 'user_add_edit':
          userAddEditPermission = true;
          break;
        case 'company_edit':
          companyEditPermission = true;
          break;
        default:
          break;
      }
    });

    return (
      bankAccountPermission &&
      approvalPolicyPermission &&
      payeeAddEditPermission &&
      paymentAddEditPermission &&
      userAddEditPermission &&
      companyEditPermission &&
      documentPublishPermission
    );
  }

  public MembershipHasPermissionTo(
    membership: userService.Membership,
    id: PermissionId | string
  ): boolean {
    if (membership == null) {
      return false;
    }
    if (!ko.isObservable(membership.permissions)) {
      return false;
    }
    const permissions = membership.permissions();

    let retVal = false;
    let idName = `unknown[${id}]`;

    if (typeof id === 'string') {
      retVal = $.inArray(id, permissions) >= 0;
    }

    if (id === PermissionId.BANK_ACCOUNT_ADD_EDIT) {
      idName = 'bank_account_add_edit';
      retVal = $.inArray('bank_account_add_edit', permissions) >= 0;
    } else if (id === PermissionId.APPROVAL_POLICY_ADD_EDIT) {
      idName = 'approval_policy_add_edit';
      retVal = $.inArray('approval_policy_add_edit', permissions) >= 0;
    } else if (id === PermissionId.PAYEE_ADD_EDIT) {
      idName = 'payee_add_edit';
      retVal = $.inArray('payee_add_edit', permissions) >= 0;
    } else if (id === PermissionId.PAYMENT_APPROVE) {
      idName = 'payment_approve';
      retVal = $.inArray('payment_approve', permissions) >= 0;
    } else if (id === PermissionId.PAYMENT_ADD_EDIT) {
      idName = 'payment_add_edit';
      retVal = $.inArray('payment_add_edit', permissions) >= 0;
    } else if (id === PermissionId.DOCUMENT_PUBLISH) {
      idName = 'document_publish';
      retVal = $.inArray('document_publish', permissions) >= 0;
    } else if (id === PermissionId.USER_ADD_EDIT) {
      idName = 'user_add_edit';
      retVal = $.inArray('user_add_edit', permissions) >= 0;
    } else if (id === PermissionId.COMPANY_EDIT) {
      idName = 'company_edit';
      retVal = $.inArray('company_edit', permissions) >= 0;
    }
    return retVal;
  }

  public getAccountingFirmMembership(): userService.Membership {
    const accountingFirmCompanyId = this.AccountingFirmCompanyId();
    if (!accountingFirmCompanyId) {
      return null;
    }

    return this.getMembershipByCompanyId(accountingFirmCompanyId);
  }

  public getMembershipByCompanyId(companyId: string): userService.Membership {
    const accountingFirmMembership = userService.Instance.memberships().find(
      (membership) => membership.company().id() == companyId
    );
    return accountingFirmMembership;
  }

  /**
  @return {Boolean} Returns either active id of undefined
  */
  public CompanyId: KnockoutComputed<string> = ko.computed({
    read: () => {
      if (this.membership() === undefined) {
        return undefined;
      }
      if (!ko.isObservable(this.membership().company)) {
        return undefined;
      }
      return this.membership().company().id();
    },
    deferEvaluation: true,
  });

  public AccountingFirmCompanyId: KnockoutComputed<string> = ko.computed({
    read: () => {
      if (this.membership() === undefined) {
        return undefined;
      }
      if (!ko.isObservable(this.membership().company)) {
        return undefined;
      }
      return this.membership().company().accountingFirmCompanyId();
    },
    deferEvaluation: true,
  });

  public companyMembershipCompanyAccountingFirmClientUserId: KnockoutComputed<string> = ko.computed(
    {
      read: () => {
        if (this.membership() === undefined) {
          return undefined;
        }
        return this.membership().companyAccountingFirmClientUserId();
      },
      deferEvaluation: true,
    }
  );

  public companyAccountingFirmClientId = ko.computed({
    read: () => {
      if (this.membership() === undefined) {
        return undefined;
      }
      if (!ko.isObservable(this.membership().company)) {
        return undefined;
      }
      return this.membership().company().companyAccountingFirmClientId();
    },
    deferEvaluation: true,
  });

  public DisplayName = ko.pureComputed(() => {
    if (this.membership() === undefined) {
      return undefined;
    }
    if (!ko.isObservable(this.membership().company)) {
      return undefined;
    }
    if (
      this.membership().company().shouldUseOperatingNameForDisplay() &&
      this.membership().company().operatingName()
    ) {
      return this.membership().company().operatingName();
    }
    return this.membership().company().name();
  });

  public CompanyName = ko.pureComputed({
    read: () => {
      if (this.membership() === undefined) {
        return undefined;
      }
      if (!ko.isObservable(this.membership().company)) {
        return undefined;
      }
      return this.membership().company().name();
    },
    deferEvaluation: true,
  });

  public CompanyCreditCardEnabled(): boolean | undefined {
    if (this.membership() === undefined) {
      return undefined;
    }
    if (!ko.isObservable(this.membership().company)) {
      return undefined;
    }
    return this.membership().company().isCreditCardOptionAvailable();
  }

  public CompanyOperatingName(): string {
    if (this.membership() === undefined) {
      return undefined;
    }
    if (!ko.isObservable(this.membership().company)) {
      return undefined;
    }
    if ($.trim(this.membership().company().operatingName())) {
      return this.membership().company().operatingName();
    }
    return this.membership().company().name();
  }

  public CompanyCountry: KnockoutComputed<string> = ko.computed({
    read: () => {
      if (this.membership() === undefined) {
        return 'CA';
      }
      if (!ko.isObservable(this.membership().company)) {
        return 'CA';
      }
      return this.membership().company().country();
    },
    deferEvaluation: true,
  });

  public CompanyType(): string {
    if (this.membership() === undefined) {
      return undefined;
    }
    if (!ko.isObservable(this.membership().company)) {
      return undefined;
    }
    return this.membership().company().companyType();
  }

  public IsDemo: KnockoutComputed<boolean> = ko.pureComputed({
    read: () => {
      if (this.membership() === undefined) {
        return false;
      }
      if (!ko.isObservable(this.membership().company)) {
        return false;
      }
      return this.membership().company().demo();
    },
    deferEvaluation: true,
  });

  public MembershipId(): string {
    if (this.membership() === undefined) {
      return undefined;
    }
    return this.membership().id();
  }

  // @todo we need to remove this logic eventually in new redesign
  public IsPaymentAdded: KnockoutComputed<boolean> = ko.computed({
    read: () => {
      if (this.membership() === undefined) {
        return false;
      }
      return this.membership().company().paymentAdded();
    },
    deferEvaluation: true,
  });

  // @todo we need to remove this logic eventually in new redesign
  public IsProfileComplete: KnockoutComputed<boolean> = ko.computed({
    read: () => {
      if (this.membership() === undefined) {
        return false;
      }
      return this.membership().company().profileComplete();
    },
    deferEvaluation: true,
  });

  public SetLastCompanyId(id: any) {
    // Strip out any non-guid characters
    id = id.toLowerCase();
    id = id.replace(/[^a-z0-9-]/g, '');

    this.lastCompanyStorage.set(LAST_COMPANY_STORAGE_KEY, id);
  }

  public GetLastCompanyId() {
    return $.localStorage.get(LAST_COMPANY_STORAGE_KEY);
  }

  public SelectCompany(companyInfo?: any): JQueryPromise<any> {
    const oldCompanyId = this.CompanyId();
    if (companyInfo === undefined) {
      if (this.lastCompanyStorage.isSet(LAST_COMPANY_STORAGE_KEY)) {
        companyInfo = this.lastCompanyStorage.get(LAST_COMPANY_STORAGE_KEY);
      } else {
        companyInfo = 0;
      }
    } else {
      this.lastCompanyStorage.set(LAST_COMPANY_STORAGE_KEY, companyInfo);
    }

    return system
      .defer((dfd) => {
        // release previous subscription if exists
        if (this.userService.memberships().length == 0) {
          dfd.resolve();
          return;
        }
        let companyIndex = 0;
        if ($.isNumeric(companyInfo)) {
          companyIndex = companyInfo;
        } else {
          const matchingCompanies = $.grep(
            this.userService.memberships(),
            (checkMembership, checkIndex) => {
              if (checkMembership.company().id() == companyInfo) {
                companyIndex = checkIndex;
                return true;
              }
              return undefined;
            }
          );
        }

        this.selectedCompanyIndex(companyIndex);

        this.status('loaded');

        app.trigger('companyProfile:companyChanged');

        if (oldCompanyId !== undefined) {
          EventsService.emit('companyProfile:companyChanged');
        }

        // These will be populated as user_AuthenticatedId (UserId) and user_AccountId (CompanyId) in App Insights
        window.appInsights.setAuthenticatedUserContext(this.userService.UserId(), this.CompanyId());

        // Update recently visited companies
        const recentCompaniesKey = `recentCompanies_${userService.Instance.UserId()}`;
        let recentCompanyIds = $.localStorage.get(recentCompaniesKey);
        if (!recentCompanyIds) {
          recentCompanyIds = [];
        }

        const updatedRecentCompanyIds = [this.CompanyId()];
        $.each(recentCompanyIds, (index, recentCompanyId) => {
          if (recentCompanyId != this.CompanyId()) {
            updatedRecentCompanyIds.push(recentCompanyId);
          }
        });
        $.localStorage.set(recentCompaniesKey, updatedRecentCompanyIds);

        RealtimeService.subscribeToCompany(this.CompanyId());

        if (this.updateNotificationpSubscription) {
          this.updateNotificationpSubscription.off();
        }
        this.updateNotificationpSubscription = app.on(RealtimeEvent.Company).then((companyId) => {
          // If the company you have currently selected is updated then refresh the profile (ex: company name changes)
          if (companyId === this.CompanyId()) {
            this.LoadCompanyProfile(companyId);
          }
        }, this);

        // If QUEUE_PAYMENTS capability is rejected show a modal to re-subscribe
        if (this.membership().isPendingBillingRedebit()) {
          app.trigger('billing:showDebitFailedModal');
        }
        // If user has a negative wallet balance capability rejected (they must cover their negative balance prior to making payments)
        if (this.membership().mustCoverNegativeWalletBalance()) {
          // Only show the negative wallet balance modal if company can make payments
          if (this.membership().isPaymentsCapable()) {
            negativeBalanceModal.show(); // @todo show negative balance modal
          }
        }

        const isReauthenticationRequired = this.membership().isReauthenticationRequired();
        if (isReauthenticationRequired) {
          app.trigger('user:showSessionTimeout', { reloadAfterAuthenticate: false });
        }

        dfd.resolve();
      })
      .promise();
  }

  public Add(profile): JQueryPromise<any> {
    return system
      .defer((dfd) => {
        // TODO: Error handling
        this.userService
          .AuthorizedPut(`${import.meta.env.APP_URLS_API_URL}/${API_PREFIX}/companies`, profile)
          .done((profileData) => {
            if (plootoUtils.IsNullOrUndefined(profileData) || profileData.error === true) {
              dfd.reject(profileData);
              return;
            }

            dfd.resolve(profileData);
          })
          .fail(() => {
            dfd.reject();
          });
      })
      .promise();
  }

  public AcceptInvite(companyId: string, membershipId: string): JQueryPromise<any> {
    return system.defer((dfd) => {
      // TODO: Error handling
      this.userService
        .AuthorizedPost(
          `${
            import.meta.env.APP_URLS_API_URL
          }/${API_PREFIX}/companies/${companyId}/users/${membershipId}/accept`
        )
        .done((profileData) => {
          if (plootoUtils.IsNullOrUndefined(profileData) || profileData.error === true) {
            dfd.reject(profileData);
            return;
          }
          dfd.resolve();
        })
        .fail(() => {
          dfd.reject();
        });
    });
  }

  public UpdateProfile(newProfile): JQueryPromise<any> {
    return system
      .defer((dfd) => {
        // TODO: Error handling
        this.userService
          .AuthorizedPost(
            `${
              import.meta.env.APP_URLS_API_URL
            }/${API_PREFIX}/companies/${this.profile().id()}/profile`,
            plootoUtils.CSRFTokenAuthorize(newProfile)
          )
          .done((profileData) => {
            if (plootoUtils.IsNullOrUndefined(profileData) || profileData.error === true) {
              dfd.reject(profileData);
              return;
            }
            ko.mapping.fromJS(profileData, {}, this.profile);
            dfd.resolve();
          })
          .fail(() => {
            dfd.reject();
          });
      })
      .promise();
  }

  public OnLoaded(callback: any): JQueryPromise<any> {
    return system
      .defer((dfd) => {
        if (this.status() === 'loaded') {
          const response = callback();
          dfd.resolve(response);
        } else {
          const loaderSubscription: KnockoutSubscription = this.status.subscribe((newStatus) => {
            if (newStatus === 'loaded') {
              loaderSubscription.dispose();
              const response = callback();
              dfd.resolve(response);
            }
            if (newStatus === 'error') {
              loaderSubscription.dispose();
            }
          });
        }
      })
      .promise();
  }

  public UploadLogo(file: any): JQueryPromise<any> {
    return system
      .defer((dfd) => {
        const formData = new FormData();
        formData.append(file.name, file);
        const url = `${
          import.meta.env.APP_URLS_API_URL
        }/${API_PREFIX}/companies/${this.profile().id()}/profile/uploadLogo`;
        this.userService
          .AuthorizedUpload(url, formData)
          .then((data) => {
            if (plootoUtils.IsNullOrUndefined(data) || data.error === true) {
              dfd.reject(data);
              return;
            }
            this.profile().logoLastModified(new Date());

            dfd.resolve();
          })
          .fail((error) => {
            dfd.reject(error);
          });
      })
      .promise();
  }

  public DeleteLogo(): JQueryPromise<any> {
    return system
      .defer((dfd) => {
        const url = `${
          import.meta.env.APP_URLS_API_URL
        }/${API_PREFIX}/companies/${this.profile().id()}/profile/deleteLogo`;
        this.userService
          .AuthorizedDelete(url)
          .then((data) => {
            if (plootoUtils.IsNullOrUndefined(data) || data.error === true) {
              dfd.reject(data);
              return;
            }
            this.profile().logoLastModified(new Date());
            dfd.resolve();
          })
          .fail((error) => {
            dfd.reject(error);
          });
      })
      .promise();
  }

  public GetLogoOriginal(): JQueryPromise<any> {
    return system
      .defer((dfd) => {
        const url = `${
          import.meta.env.APP_URLS_API_URL
        }/${API_PREFIX}/companies/${this.profile().id()}/profile/logo?timestamp=${this.profile()
          .logoLastModified()
          .getMilliseconds()}`;
        this.userService
          .AuthorizedGet(url)
          .done((data) => {
            dfd.resolve(data);
          })
          .fail((error) => {
            dfd.reject(error);
          });
      })
      .promise();
  }

  public isLimitedDomesticPaymentsFeatureEnabled = ko.pureComputed<boolean>({
    read: () => {
      const isFeatureEnabled = () => {
        const companyId = this.CompanyId();
        const companyProfile = this.profile();
        const hasLimitedCompanyCapabilityFeatureFlag =
          plootoUtils.IsFeatureEnabled(
            LegacyFeatureFlag.LIMITED_DOMESTIC_PAYMENTS_CAPABILITY_ONBOARDING,
            companyId
          ) ||
          plootoUtils.IsFeatureEnabledForCompanyProfile(
            companyProfile,
            LegacyFeatureFlag.LIMITED_DOMESTIC_PAYMENTS_CAPABILITY_ONBOARDING
          );
        return hasLimitedCompanyCapabilityFeatureFlag;
      };

      return isFeatureEnabled();
    },
    deferEvaluation: true,
  });

  public hasApprovedLimitedDomesticPaymentsCapability = ko.pureComputed<boolean>({
    read: () => {
      const isCapabilityEnabled = () => {
        if (this.membership() === undefined) {
          return false;
        }
        if (!ko.isObservable(this.membership().company)) {
          return false;
        }

        return this.membership().company().hasApprovedLimitedDomesticPaymentsCapability();
      };

      return isCapabilityEnabled();
    },
    deferEvaluation: true,
  });

  // Check if feature enabled for this company. Or if it's an accounting-wide feature and is enabled for this accounting firm client.
  public isFeatureEnabled(featureName: LegacyFeatureFlag) {
    return (
      plootoUtils.IsFeatureEnabled(featureName, this.CompanyId()) ||
      plootoUtils.IsFeatureEnabled(featureName, this.AccountingFirmCompanyId())
    );
  }

  /**
   * Returns `CompanyService.profile().subscriptionFeatures().isDualControlsEnabled()`
   */
  public isDualControlsEnabled = ko.computed({
    read: () => this.profile()?.subscriptionFeatures()?.isDualControlsEnabled(),
    deferEvaluation: true,
  });

  /**
   * Returns `CompanyService.profile().subscriptionFeatures().disableCustomPermissions()`
   */
  public disableCustomPermissions = ko.computed({
    read: () => this.profile()?.subscriptionFeatures()?.disableCustomPermissions(),
    deferEvaluation: true,
  });

  /**
   * Returns `CompanyService.profile().subscriptionFeatures().isLockedSubscription()`
   */
  public isLockedSubscription = ko.computed({
    read: () => this.profile()?.subscriptionFeatures()?.isLockedSubscription?.() ?? false,
    deferEvaluation: true,
  });

  /**
   * Returns `CompanyService.profile().subscriptionFeatures().isClientOnboardingBannerVisible()`
   */
  public isClientOnboardingBannerVisible = ko.computed({
    read: () => this.profile()?.subscriptionFeatures()?.isClientOnboardingBannerVisible?.() ?? true,
    deferEvaluation: true,
  });

  /**
   * Returns `CompanyService.profile().isAccountingFirm()`
   */
  public isAccountingFirm = ko.computed({
    read: () => this.profile()?.isAccountingFirm(),
    deferEvaluation: true,
  });

  /**
   * Returns `CompanyService.profile().subscriptionFeatures().isCollectClientAddonFeesVisible()`
   */
  public isCollectClientAddonFeesVisible = ko.computed({
    read: () =>
      this.profile()?.subscriptionFeatures()?.isCollectClientAddonFeesVisible?.() ?? false,
    deferEvaluation: true,
  });
}

export const Instance = new CompanyService();
