import * as ko from 'knockout';

import { FEATURE_TOGGLES } from '@/config/env';
import { LegacyFeatureFlag } from '@/features/feature-flags/types/LegacyFeatureFlag';

export function CSRFTokenAuthorize<T extends object>(data?: T): T {
  if (data == null) {
    data = {} as T;
  }
  // @ts-expect-error Intentionally write this hidden property, it's not part of the request type.
  data.__RequestVerificationToken = $(
    '#__AjaxAntiForgeryForm input[name=__RequestVerificationToken]'
  ).val();
  return data;
}

export function DisableForm(form: HTMLFormElement) {
  const $form = $(form);

  $form.addClass('form-submitting').find(':input').prop('disabled', true).addClass('busy');

  $form.find('.btn-checkbox').addClass('ko-disabled');
}
export function EnableForm(form: HTMLFormElement) {
  const $form = $(form);
  $form
    .removeClass('form-submitting')
    // String holds data, not user-displayed text.
    // eslint-disable-next-line @cspell/spellchecker
    .find(':input:not(.kodisabled)')
    .prop('disabled', false)
    .removeClass('busy');

  $form.find('.btn-checkbox').removeClass('ko-disabled');
}

export function HandleFormSubmit(form) {
  const $form = $(form);

  $form.find('.error').removeClass('error');
  $form.find('.error-label').removeClass('error-label');
  $form.find('.contains-form-error-bubble').removeClass('contains-form-error-bubble');
  $form.find('ul[name=errors]').remove();
  $form.closest('.plooto-content,.padded_user_page').find('.errors-container').hide();
  $form.removeClass('form-contains-errors');
}
export function IsNullOrUndefined(value: any): boolean {
  return value === undefined || value === null;
}

export function ValidateForm(
  form,
  formErrorsCallback?: (formErrors: Array<object>) => void
): boolean {
  const formData = $(form).serializeArray();
  const formErrors = [];
  const errorFields = [];
  // serializeArray can return duplicates if there are name collisions
  $.map(formData, (checkItem: any) => {
    if (
      $.grep(errorFields, (existingItem: any) => existingItem.name == checkItem.name).length > 0
    ) {
      return;
    }
    errorFields.push(checkItem);
  });

  $.each(errorFields, (i, field: any) => {
    // we select first field by name. this might be invisible in the event that field has been hidden by overwrite control
    const $field = $(form).find(`[name*='${field.name}']`);
    const fieldHidden = $field.is(':visible');
    const checkHiddenValidation = $field.hasClass('validation-check-hidden');

    if (!fieldHidden && !checkHiddenValidation) {
      return;
    }
    const errorMessage = $field.triggerHandler('_validate');
    if (errorMessage === undefined) {
      return;
    }

    formErrors.push({
      name: field.name,
      errors: [errorMessage],
    });
  });
  if (formErrors.length == 0) {
    return true;
  }
  HandleFormError(form, {
    error: true,
    fields: formErrors,
  });
  formErrorsCallback?.(formErrors);
  return false;
}

export function ForceFormError(form, fieldName, error) {
  HandleFormError(form, {
    error: true,
    fields: [
      {
        name: fieldName,
        errors: [error],
      },
    ],
  });
}

export type ErrorInfoField = {
  name: string;
  errors: Array<string>;
};

export type ErrorInfo = {
  error: boolean;
  fields: Array<ErrorInfoField>;
};

export function HandleFormError(form: Element, errorInfo: ErrorInfo) {
  // validate parameters
  if (form === null || form === undefined) {
    return;
  }
  if (errorInfo === null || errorInfo === undefined) {
    return;
  }
  // make sure we are processing error info
  if (errorInfo.error !== true) {
    return;
  }

  // make sure error has to do with form fields
  if (errorInfo.fields === undefined || !$.isArray(errorInfo.fields)) {
    return;
  }

  // Group duplicate fields together, combining and de-duplicating their error messages. Maintain
  // iteration order by using a Map and Set.
  const errorFields: Array<ErrorInfoField> = Array.from(
    errorInfo.fields.reduce((map, { name, errors }) => {
      const set = map.get(name);
      if (set != null) {
        errors.forEach((error) => set.add(error));
      } else {
        map.set(name, new Set(errors));
      }
      return map;
    }, new Map<string, Set<string>>())
  ).map(([name, errors]) => ({ name, errors: Array.from(errors) }));

  const $form = $(form);

  $form.removeClass('form-contains-errors');

  // let's add friendly errors to container
  const $errorsContainer = $form
    .closest('.plooto-content,.padded_user_page')
    .find('.errors-container');
  let shouldSlideErrors = false;

  if ($errorsContainer.children().length == 0) {
    shouldSlideErrors = true;
  }
  $errorsContainer.hide();
  $errorsContainer.empty();
  const $errorsContainerHeader = $('<div class="errors-container-header" />');
  const $errorsContainerBody = $('<div class="errors-container-body" />');
  $errorsContainer.append($errorsContainerHeader).append($errorsContainerBody);

  let firstField = null;

  const $tooltip = $('<div class="tooltip-error"></div>');

  $.each(errorFields, (fieldIndex, fieldInfo) => {
    // get actually visible control corresponding to the filed name (original might be hidden)
    // visible version will be used to add border to
    let allFields = $form.find(`[name='${fieldInfo.name}']`).filter(':first');
    if (allFields.length == 0) {
      allFields = $form.find(`[id='${fieldInfo.name}']`).filter(':first');
    }

    let $field: JQuery;
    let $scroller: JQuery;

    if (allFields.length > 0) {
      $field = allFields.first();
      const $fieldLabel = $field.parent().find('label');
      const { errors } = fieldInfo;
      const fieldLabelText = $fieldLabel;
      const errorTextPrefix = $fieldLabel.length > 0 ? `${$fieldLabel.text()} - ` : '';

      $.each(errors, (errorIndex, errorText) => {
        if (!$errorsContainer.attr('data-error-body')) {
          $errorsContainerBody.append(
            $('<div />')
              .text(errorTextPrefix + errorText)
              .data('for-field-name', fieldInfo.name)
          );
        }
      });
      $fieldLabel.addClass('error-label');
      $form.addClass('form-contains-errors');
    }

    const fields = allFields.filter(':visible');

    if (fields.length === 0) {
      return;
    }

    $field = fields.first();

    $field.addClass('error');

    $scroller = $field
      .parents()
      .filter(function () {
        return $(this).css('overflow') != 'visible';
      })
      .first();
    const removeTooltip = (tooltip: JQuery) => {
      tooltip.parent().removeClass('contains-form-error-bubble');
      tooltip.remove();
    };
    let $scrollEvent;
    $field.data('errors', fieldInfo.errors);
    if (!$field.data('errors-checking-bound')) {
      $field.data('errors-checking-bound', true);
      $field.focus(() => {
        const errors = $field.data('errors');
        const { tagName } = $field.get(0);

        if (errors == undefined || errors.length == 0) {
          return;
        }

        $tooltip.empty();
        $.each(errors, (errorIndex, errorText) => {
          errorText = errorText.replace(/\(.*?\)$/, '').trim(); // Remove () at the  end of error messages
          $tooltip.append($('<div/>').text(errorText));
          $tooltip.html($tooltip.html().replace(/\n/g, '<br/>'));

          // Store what tooltip was visibile to the user
          window.appInsights.trackEvent('errorTooltip', { name: fieldInfo.name, error: errorText });
        });
        let linkPosition = $field.position();

        // Radio / Checkbox errors will be relative to the actual pl-icon
        if ($field.attr('type') == 'radio' || $field.attr('type') == 'checkbox') {
          linkPosition = $field.prev().position();
          linkPosition.left -= 6;
        }

        // Date Picker errors should render above, as the picker popover usually renders beneath the field.
        if ($field.hasClass('datePicker')) {
          linkPosition.top -= $field.outerHeight() + 8;
          $tooltip.addClass('arrow-bottom');
        }

        const yOffset = linkPosition.top + 45;

        if ($field.hasClass('btn')) {
          linkPosition.top += 16;
        }

        if (!$tooltip.hasClass('arrow-bottom')) {
          linkPosition.top += 37;
        }

        $tooltip.css({
          top: linkPosition.top,
          left: linkPosition.left,
        });
        $field.parent().append($tooltip);

        $scroller.scroll(() => {
          removeTooltip($tooltip);
          $scroller.unbind('scroll');
        });
      });
      $field.blur(() => {
        removeTooltip($tooltip);
        $scroller.unbind('scroll');
      });
      $field.on('change keyup paste mousedown', () => {
        $field.removeClass('error');
        const $fieldLabel = $field.parent().find('label').removeClass('error-label');
        $fieldLabel.parent().removeClass('contains-form-error-bubble');

        $field.data('errors', []);
        removeTooltip($tooltip);
        $scroller.unbind('scroll');
      });
    }

    if (firstField == null) {
      const $divs = $field.parents();
      const $scrollers = $divs.filter(function () {
        return $(this).css('overflow') != 'visible';
      });

      firstField = $field;

      if ($scrollers.length > 1) {
        $scroller = $scrollers.first();

        $scroller.animate(
          {
            scrollLeft: $scroller.scrollLeft() + $field.position().left - 30,
            scrollTop: $scroller.scrollTop() + $field.position().top - 190,
          },
          30,
          () => {
            setTimeout(() => {
              $field.select();
              $field.focus(); // Select fields need to be focused to show the error tooltip

              if ($field.offset() && $field.offset().top > 0) {
                const scrollPos = $field.offset().top;
                $(window).scrollTop(scrollPos - 170 > 0 ? scrollPos - 170 : 0);
              }
            }, 100);
          }
        );
      } else {
        setTimeout(() => {
          $field.select();
          $field.focus();
          if ($field.offset() && $field.offset().top > 0) {
            const scrollPos = $field.offset().top;
            $(window).scrollTop(scrollPos - 170 > 0 ? scrollPos - 170 : 0);
          }
        }, 100);

        const $body = $('body');

        const scrollTo = $field.offset().top - 170;
        if ($body.scrollTop() > scrollTo) {
          $('html,body').animate(
            {
              scrollTop: scrollTo,
            },
            'fast'
          );
        }
      }
    }
  });
  if ($errorsContainer.attr('data-error-body')) {
    $errorsContainerBody
      .empty()
      .append($('<div />').text($errorsContainer.attr('data-error-body')));
  }
  if ($errorsContainerBody.children().length > 0) {
    $errorsContainerHeader.text('Please review your submission');
    if (shouldSlideErrors) {
      $errorsContainer.slideDown();
    } else {
      $errorsContainer.show();
    }
  }
  $form.trigger('validationFinished');
}

export class BusyIndicator {
  private blockout: JQuery;

  public start(): void {
    const body = $('body');
    // String holds data, not user-displayed text.
    // eslint-disable-next-line @cspell/spellchecker
    this.blockout = $('<div class="busyBlockout"></div>').css({ 'z-index': 1000 });
    body.append(this.blockout);
  }

  public end(): void {
    this.blockout.remove();
  }
}

export function DisplayStatusMessage(message: string) {
  const $body = $('body');

  const $existingMessage = $body.find('.statusMessage');
  if ($existingMessage.length != 0) {
    return;
  }

  const $statusElement = $('<div/>', {
    text: message,
    class: 'statusMessage',
  }).appendTo($body);

  const hideMessage = () => {
    if ($statusElement.hasClass('active')) {
      $statusElement.removeClass('active');
      setTimeout(() => {
        $statusElement.remove();
      }, 500);
    }
  };

  setTimeout(() => {
    $statusElement.addClass('active');

    $(document).one('click', hideMessage).one('scroll', hideMessage);
  }, 50);
}

export function HashCode(str: string): number {
  let hash = 0;
  if (str.length == 0) {
    return hash;
  }
  for (let i = 0; i < str.length; i += 1) {
    const char = str.charCodeAt(i);
    // Port of Java's String.hashCode requires bitwise op.
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + char;
    // Port of Java's String.hashCode requires bitwise op.
    // eslint-disable-next-line no-bitwise
    hash &= hash; // Convert to 32bit integer
  }
  return hash;
}
export function GetUriParams(): Array<any> {
  const vars = [];
  let hash;
  const hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
  for (let i = 0; i < hashes.length; i += 1) {
    hash = hashes[i].split('=');
    const [key, value] = hash;
    vars.push(key);
    vars[key] = value;
  }
  return vars;
}

export function GetSearchParams(): URLSearchParams {
  const { hash, href } = window.location;
  let fixedUrl = href;
  if (hash) {
    const pathFromHash = href.endsWith('/') ? hash.substr(1) : `/${hash.substr(1)}`;
    fixedUrl = `${href.replace(hash, pathFromHash)}`;
  }
  return new URL(fixedUrl).searchParams;
}

export function IsWhiteSpaceOnly(value: string): boolean {
  return value.trim() == '';
}

/**
 * Old-style feature toggles from before Split.io
 * @param featureName Environment variable name
 * @param companyId Plooto company ID
 */
export function IsFeatureEnabled(featureName: LegacyFeatureFlag, companyId?: string): boolean {
  const featureConfig = FEATURE_TOGGLES[featureName];

  // If feature config is NULL then it's available for everyone
  if (featureConfig === null) {
    return true;
  }

  if (!companyId) {
    return false;
  }

  const companyIdHashCode = HashCode(companyId);
  const isFeatureEnabled = $.inArray(companyIdHashCode, featureConfig) >= 0;

  return isFeatureEnabled;
}

export function IsFeatureEnabledForCompanyProfile(
  profile: any,
  featureName: LegacyFeatureFlag
): boolean {
  // Ensure we try with a profile that was loaded. If profile was not loaded at time of calling this function feature would be disabled.
  if (!profile) {
    return false;
  }
  if (ko.isObservable(profile.featuresInTesting)) {
    const doesCompanyHaveFeature = (profile.featuresInTesting() as Array<any>).some(
      (x) => x.featureName() == featureName && x.active()
    );
    if (doesCompanyHaveFeature) {
      return true;
    }
    return false;
  }

  return false;
}

export function GetOrdinal(numberToConvert: number): string {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = numberToConvert % 100;
  return numberToConvert + (s[(v - 20) % 10] || s[v] || s[0]);
}
export function SimilarText(first, second, percent = null) {
  //  discuss at: http://phpjs.org/functions/similar_text/
  // original by: Rafał Kukawski (http://blog.kukawski.pl)
  // bugfixed by: Chris McMacken
  // bugfixed by: Jarkko Rantavuori original by findings in stackoverflow (http://stackoverflow.com/questions/14136349/how-does-similar-text-work)
  // improved by: Markus Padourek (taken from http://www.kevinhq.com/2012/06/php-similartext-function-in-javascript_16.html)
  //   example 1: similar_text('Hello World!', 'Hello phpjs!');
  //   returns 1: 7
  //   example 2: similar_text('Hello World!', null);
  //   returns 2: 0

  if (
    first === null ||
    second === null ||
    typeof first === 'undefined' ||
    typeof second === 'undefined'
  ) {
    return 0;
  }

  first = String(first);
  second = String(second);

  let pos1 = 0;
  let pos2 = 0;
  let max = 0;
  const firstLength = first.length;
  const secondLength = second.length;
  let p;
  let q;
  let l;
  let sum;

  max = 0;

  for (p = 0; p < firstLength; p += 1) {
    for (q = 0; q < secondLength; q += 1) {
      for (
        l = 0;
        p + l < firstLength && q + l < secondLength && first.charAt(p + l) === second.charAt(q + l);
        l += 1
        // eslint-disable-next-line curly
      );
      if (l > max) {
        max = l;
        pos1 = p;
        pos2 = q;
      }
    }
  }

  sum = max;

  if (sum) {
    if (pos1 && pos2) {
      sum += SimilarText(first.substr(0, pos1), second.substr(0, pos2));
    }

    if (pos1 + max < firstLength && pos2 + max < secondLength) {
      sum += SimilarText(
        first.substr(pos1 + max, firstLength - pos1 - max),
        second.substr(pos2 + max, secondLength - pos2 - max)
      );
    }
  }

  if (!percent) {
    return sum;
  }
  return (sum * 200) / (firstLength + secondLength);
}

export function GroupBy(array, f) {
  const groups = {};
  array.forEach((o) => {
    const group = JSON.stringify(f(o));
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });

  return Object.keys(groups).map((group) => groups[group]);
}

export function IsNullOrWhitespace(value: string): boolean {
  return value === null || value.trim() === '';
}

export function IsNullOrUndefinedOrWhitespace(value: string): boolean {
  return value === null || value === undefined || value.trim() === '';
}

// Allows you to Math.Round up to a certain decimal point
export function PrecisionRound(number: number, precision: number): number {
  const factor = 10 ** precision;
  return Math.round(number * factor) / factor;
}

export function GenerateRandomCharacters(length: number): string {
  const getRandomAlphaNumericCharacters = () => {
    let randomAlphaNumeric = '';
    const alphaNumbericCharacters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const lengthOfRandomCharacters = length;

    for (let i = 0; i < lengthOfRandomCharacters; i += 1) {
      randomAlphaNumeric += alphaNumbericCharacters.charAt(
        Math.floor(Math.random() * alphaNumbericCharacters.length)
      );
    }

    return randomAlphaNumeric;
  };

  return getRandomAlphaNumericCharacters();
}
