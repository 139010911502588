enum RealtimeEvent {
  Account = 'realtime:account',
  Company = 'realtime:company',
  IdentityVerification = 'realtime:identityVerification',
  Members = 'realtime:members',
  Membership = 'realtime:membership',
  Offline = 'realtime:offline',
  Online = 'realtime:online',
  Payee = 'realtime:payee',
  Payment = 'realtime:payment',
  PaymentRequest = 'realtime:paymentRequest',
}

export default RealtimeEvent;
