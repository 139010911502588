import { CompanyFeatureFlagsService, LegacyFeatureFlag } from '@/features/feature-flags';
import EventService, { SubscribedEvent } from '@/legacy/services/eventsService';
import SessionStorageService from '@/legacy/services/storage/SessionStorageService';

function isFeatureRetainFormDataEnabled(): boolean {
  return CompanyFeatureFlagsService.isFeatureFlagEnabled(
    LegacyFeatureFlag.FEATURE_RETAIN_FORM_DATA
  );
}

enum FormDataStorageKey {
  SELECTED_BILLS = 'selected-bills',
  SELECTED_INVOICES = 'selected-invoices',
  PAYMENTS = 'payments',
  PAYMENT_REQUESTS = 'payment-requests',
  PAYMENT_REQUEST = 'payment-request',
}

class FormDataStorageService {
  private subscriptions: Array<SubscribedEvent> = [];

  public attachEventHandlers(): void {
    this.addSubscription('companyProfile:companyChanged', () => {
      this.clear();
    });
    this.addSubscription('user:logout', () => {
      this.clear();
    });
  }

  public detachEventHandlers(): void {
    this.subscriptions.forEach((subscription) => {
      EventService.off(subscription);
    });
    this.subscriptions = [];
  }

  public setItem(key: FormDataStorageKey, data: unknown): void {
    SessionStorageService.setItem(key, data);
  }

  public getItem<T>(key: string): T {
    return SessionStorageService.getItem<T>(key);
  }

  public removeItem(key: FormDataStorageKey): void {
    SessionStorageService.removeItem(key);
  }

  public clear(): void {
    this.removeItem(FormDataStorageKey.SELECTED_BILLS);
    this.removeItem(FormDataStorageKey.PAYMENTS);
    this.removeItem(FormDataStorageKey.PAYMENT_REQUESTS);
    this.removeItem(FormDataStorageKey.PAYMENT_REQUEST);
  }

  private addSubscription(event: string, callback: () => void): void {
    this.subscriptions.push(EventService.on(event, callback));
  }
}

const Instance = new FormDataStorageService();

export default Instance;
export { isFeatureRetainFormDataEnabled, FormDataStorageKey };
