import * as segmentAnalytics from 'segmentAnalytics';

import CompanyEventsService from '@/legacy/services/companyEventService';

import NewPaymentRequestEventName from './helper';

/**
 * Analytics events listener which triggers Segment Analytics
 * tracking for Payment requests.
 * This gets called on registerAnalyticsEventsListeners.ts and registers event handlers
 */
const analyticsEventsListener = {
  bind(): void {
    CompanyEventsService.on(
      NewPaymentRequestEventName.NewPaymentRequestCreated,
      (payload: CustomEvent) => {
        segmentAnalytics.track(NewPaymentRequestEventName.NewPaymentRequestCreated, {
          ...payload?.detail,
        });
      }
    );
  },
};

export default analyticsEventsListener;
