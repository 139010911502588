import { memo, useEffect } from 'react';
import { Tooltip, Typography } from '@mui/material';

const classes = {
  invisibleContainer: {
    display: 'contents',
    maxHeight: 'min-content',
    maxWidth: 'min-content',
  },
  pointerFix: {
    pointerEvents: 'auto',
  },
} as const;

interface Props {
  anchorEl: HTMLElement;
  title: React.ReactNode;
}

const KnockoutTooltip = ({ anchorEl, title }: Props): React.JSX.Element => {
  useEffect(() => {
    const styles = [];
    if (anchorEl.style.cssText) {
      styles.push(anchorEl.style.cssText, ';');
    }
    styles.push('pointer-events:auto;');

    anchorEl.setAttribute('style', styles.join(''));
  }, [anchorEl]);

  return (
    <Tooltip
      arrow
      style={classes.pointerFix}
      PopperProps={{ anchorEl }}
      title={<Typography component="div">{title}</Typography>}
    >
      <div
        style={classes.invisibleContainer}
        ref={(nodeElement) => {
          if (nodeElement) {
            nodeElement.appendChild(anchorEl);
          }
        }}
      />
    </Tooltip>
  );
};

export default memo(KnockoutTooltip);
