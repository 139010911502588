enum AccountingSoftwareIntegrationEventName {
  AccountingPlatformConnectionInitiated = 'Accounting Platform Connection Initiated',
  AccountingPlatformConnected = 'Accounting Platform Connected',
  AccountingPlatformDisconnected = 'Accounting Platform Disconnected',
  AccountingPlatformSync = 'Accounting Platform Sync',
}

enum AccountingSoftwareIntegrationEventsServiceEventType {
  AccountingPlatformConnectionInitiated = 'accounting-software:connection-initiated',
  AccountingPlatformConnected = 'accounting-software:connected',
  AccountingPlatformDisconnected = 'accounting-software:disconnected',
  AccountingPlatformSync = 'accounting-software:sync',
}

export {
  AccountingSoftwareIntegrationEventName,
  AccountingSoftwareIntegrationEventsServiceEventType,
};
