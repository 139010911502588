import { RouteObject } from 'react-router-dom';

import { DeferToDurandal } from '@/components/DeferToDurandal';
import reactShellDemoRoutes from '@/features/react-shell-demo/routes';

// A note about routes: all non-platform routes must be lazy-loaded to avoid bloating the JavaScript
// entry point.
//
// You can do that like this:
//
//   const { MyRoutes } = lazyImport(() => import('@/feature/routes'), 'MyRoutes');
//
// You can then spread this into the reactRoutes array below.
//
// Your routes should be an Array<RouteObject>. Your routes can have child routes. Your routes
// **must not** define a wildcard ("*") catch-all path, or you will break Durandal.

export const reactRoutes: Array<RouteObject> = [
  ...reactShellDemoRoutes,
  {
    path: '*',
    element: <DeferToDurandal />,
  },
];
