import {
  PRIMARY_CURRENCIES,
  SECONDARY_CURRENCIES,
} from '@/features/financial/dataSource/currencies';
import Currency from '@/features/financial/types/Currency';
import CurrencyCode from '@/features/financial/types/CurrencyCode';

class CurrencyService {
  private currenciesByCode: Map<CurrencyCode, Currency>;

  constructor(currencies: Array<Currency> = []) {
    this.currenciesByCode = new Map<CurrencyCode, Currency>();

    for (const currency of currencies) {
      this.currenciesByCode.set(currency.code, currency);
    }
  }

  public getCurrencies(): Array<Currency> {
    return [...this.currenciesByCode.values()];
  }

  public getCurrency(currencyCode: CurrencyCode): Currency {
    return this.currenciesByCode.get(currencyCode);
  }

  public getStandardCurrencies(): Array<Currency> {
    const retVal: Array<Currency> = [
      this.currenciesByCode.get(CurrencyCode.CAD),
      this.currenciesByCode.get(CurrencyCode.USD),
    ];
    return retVal;
  }

  public getCurrencyCodeFromCountryCode(countryCode: string): string {
    switch (countryCode) {
      case 'US':
        return CurrencyCode.USD;
      default:
        return CurrencyCode.CAD;
    }
  }
}

const Instance = new CurrencyService([...PRIMARY_CURRENCIES, ...SECONDARY_CURRENCIES]);

export default Instance;
