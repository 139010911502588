import SegmentTrackingBase from '@/legacy/features/analytics/segmentTrackingBase/baseTracking';
import { FormTrackerEventTypes } from '@/legacy/features/analytics/segmentTrackingBase/constants';
import CompanyEventService from '@/legacy/services/companyEventService';

type SupportedEnums = number | string | symbol;
abstract class SegmentButtonTrackingBase<T extends SupportedEnums> extends SegmentTrackingBase {
  private readonly buttonNames: { [key in T]: string };

  constructor(buttonNames: { [key in T]: string }) {
    super();
    this.buttonNames = buttonNames;
  }

  public onButtonClicked(buttonType: T): void {
    const contextEventName = this.getContextEventName(FormTrackerEventTypes.ButtonClicked);

    CompanyEventService.emit(contextEventName, {
      buttonName: this.buttonNames[buttonType],
      context: this.getContextName(),
    });
  }

  bindEvents(): void {
    this.bindEventsBase([FormTrackerEventTypes.ButtonClicked]);
  }
}
export default SegmentButtonTrackingBase;
