import * as companyService from '@/legacy/services/company';
import * as plootoUtils from '@/legacy/utils/plooto';

import { LegacyFeatureFlag } from '../types/LegacyFeatureFlag';

/**
 * @deprecated consider using the FeatureToggleService for new feature flags.
 */
class CompanyFeatureFlagsService {
  public static isFeatureFlagEnabled(featureFlag: LegacyFeatureFlag): boolean {
    const companyId: string = companyService.Instance.CompanyId();
    return (
      plootoUtils.IsFeatureEnabled(featureFlag, companyId) ||
      plootoUtils.IsFeatureEnabledForCompanyProfile(companyService.Instance.profile(), featureFlag)
    );
  }
}

export default CompanyFeatureFlagsService;
