function readJsonFromLocalStorage<T>(key: string, defaultValue: T): T {
  try {
    const value = window.localStorage.getItem(key);
    if (!value) {
      return defaultValue;
    }
    const json = JSON.parse(value);
    return json;
  } catch (e) {
    console.error(e, `error reading ${key} from local storage`);
    return defaultValue;
  }
}

export default readJsonFromLocalStorage;
