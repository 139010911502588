import * as React from 'react';

import PlootoPlatformProvider from './PlootoPlatformProvider';

// withPlootoPlatformProvider() creates a new component instance. We call this inline in a few
// places that do not have a convenient spot to memoize it per OriginalComponent, so we do it here
// for all to benefit.
const WrappedComponents = new WeakMap();

const withPlootoPlatformProviderImpl = (OriginalComponent: React.JSXElementConstructor<unknown>) =>
  function WrappedComponent(props) {
    return (
      <PlootoPlatformProvider>
        <OriginalComponent {...props} />
      </PlootoPlatformProvider>
    );
  };

/**
 * HOC that wraps a component with PlootoPlatformProvider
 * @param OriginalComponent Component to wrap
 * @returns Component wrapped with PlootoPlatformProvider
 */
const withPlootoPlatformProvider = (OriginalComponent: React.JSXElementConstructor<unknown>) => {
  let WrappedComponent = WrappedComponents.get(OriginalComponent);
  if (WrappedComponent == null) {
    WrappedComponent = withPlootoPlatformProviderImpl(OriginalComponent);
    WrappedComponents.set(OriginalComponent, WrappedComponent);
  }
  return WrappedComponent;
};

export default withPlootoPlatformProvider;
