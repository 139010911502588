import { apimHttpHeader, TOKEN_STORAGE_KEY } from '../config';
import { getAuthorizationHeaderValue } from '../helpers/authorizationHeader';
import { ApiError, ApiErrorResponse } from '../types/ApiError';
import { CredentialsInclusion } from '../types/CredentialsInclusion';

interface RequestOptions {
  /**
   * Allows cancellation of an API request.
   */
  abortSignal?: AbortSignal;
  /**
   * HTTP headers to include with an API request.
   */
  headers?: HeadersInit;
  /**
   * API request body (payload). A JavaScript object or `null` will be serialized as JSON. Other
   * serializable types, like FormData, will be sent with the appropriate encoding. When passing a
   * buffer, you MUST supply a Content-Type header.
   */
  body?: BodyInit | object | null;
  /**
   * Allows to include or omit credentials
   * (cookies, HTTP authentication entries, and TLS client certificates) with an API request.
   * @default CredentialsInclusion.Include
   */
  credentials?: CredentialsInclusion;
}

/**
 * Service for sending HTTP requests to the Plooto API.
 */
class ApiService {
  static tokenStorageKey = TOKEN_STORAGE_KEY;

  /**
   * Sends a GET request, returning the intermediate Response object. You MUST pass this to
   * {@see unsafe_processResponse} to complete the request flow. DO NOT call `Response#json()`
   * yourself. You may use this if you need to retrieve something from the Response object that
   * isn't present in the response body, such as response headers.
   */
  static async unsafe_getResponse(
    url: string,
    options?: RequestOptions,
    authorize = true
  ): Promise<Response> {
    const response = await ApiService.getHttpResponse('GET', url, options, authorize);

    // Only handled API errors will have 2XX OK status code.
    if (!response.ok) {
      throw new ApiError(response.statusText, 'invalid.responseStatus', undefined, response.status);
    }

    return response;
  }

  /**
   * Parses a `Response` created by {@see unsafe_getResponse}. You MUST use this if you needed to
   * retrieve something from the Response object, such as response headers.
   */
  static unsafe_parseResponse<T>(response: Response): Promise<T> {
    return this.parseJsonResponse(response);
  }

  static get<T>(url: string, options?: RequestOptions, authorize = true): Promise<T> {
    return ApiService.getJsonResponse('GET', url, options, authorize);
  }

  static patch<T>(url: string, options?: RequestOptions, authorize = true): Promise<T> {
    return ApiService.getJsonResponse('PATCH', url, options, authorize);
  }

  static put<T>(url: string, options?: RequestOptions, authorize = true): Promise<T> {
    return ApiService.getJsonResponse('PUT', url, options, authorize);
  }

  static post<T>(url: string, options?: RequestOptions, authorize = true): Promise<T> {
    return ApiService.getJsonResponse('POST', url, options, authorize);
  }

  static remove<T>(url: string, options?: RequestOptions, authorize = true): Promise<T> {
    return ApiService.getJsonResponse('DELETE', url, options, authorize);
  }

  private static async getJsonResponse<T>(
    method: string,
    url: string,
    options?: RequestOptions,
    authorize?: boolean
  ): Promise<T> {
    const response = await ApiService.getHttpResponse(method, url, options, authorize);

    // Parse responses with non 2XX OK status codes and throw them as ApiErrors.
    await ApiService.throwApiError(response);
    return ApiService.parseJsonResponse<T>(response);
  }

  private static async parseJsonResponse<T>(response: Response): Promise<T> {
    // Early-return, as `response.json()` will throw if called on a response without a body.
    if (
      response.status === 204 /* No Content */ ||
      response.headers.get('content-length') === '0' /* Empty body */
    ) {
      // XXX: there is no way to make this work without making the return type nullable. We'll have
      // to come up with something else to signal this in the type signature.
      //
      // We have to use @ts-ignore here because it only errors for strictNullChecks: true.
      //
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore Type 'undefined' is not assignable to type 'T'.
      return undefined;
    }

    let responsePayload: T & ApiErrorResponse;
    try {
      responsePayload = await response.json();
    } catch {
      throw new ApiError(
        'Could not deserialize payload to JSON.',
        'invalid.responsePayload',
        undefined,
        response.status
      );
    }

    // Plooto can use 2XX responses to send errors so we deal with it here.
    if (responsePayload.error) {
      // Regex is used to strip out error type which is embedded in the
      // error message in parentheses, e.g. "An error has occurred (error.type)".
      const errorTypeInMessageRegex = /\s\(.*\)$/g;

      const message = responsePayload.message
        ? responsePayload.message.replace(errorTypeInMessageRegex, '')
        : 'A generic error has occurred';

      throw new ApiError(message, responsePayload.type, responsePayload, response.status);
    }

    return responsePayload;
  }

  private static async throwApiError(response: Response) {
    if (response.ok) {
      return;
    }

    let responsePayload: ApiErrorResponse;
    try {
      responsePayload = await response.json();
    } catch {
      throw new ApiError(
        'Could not deserialize payload to JSON.',
        'invalid.responsePayload',
        undefined,
        response.status
      );
    }

    // If the payload does not have a type assume we can not parse it and throw an error.
    if (responsePayload.type == null) {
      throw new ApiError(response.statusText, 'invalid.responseStatus', undefined, response.status);
    }

    const errorTypeInMessageRegex = /\s\(.*\)$/g;
    const message = responsePayload.message
      ? responsePayload.message.replace(errorTypeInMessageRegex, '')
      : 'A generic error has occurred';

    throw new ApiError(message, responsePayload.type, responsePayload, response.status);
  }

  private static async getHttpResponse(
    method: string,
    url: string,
    options: RequestOptions | undefined,
    authorize: boolean | undefined
  ): Promise<Response> {
    // Setup headers.
    const requestHeaders = new Headers(options?.headers);
    Object.entries(apimHttpHeader).forEach(([name, value]) => {
      requestHeaders.append(name, value);
    });

    // This checks if the body is a known type off BodyInit (which includes XMLHttpRequestBodyInit).
    // If it is, then don't encode it as `fetch` will encode it as appropriate / they should pass in
    // the correct Content-Type header themselves.
    //
    // Otherwise, we'll assume it's JSON-serializable and do that ourselves.
    const body = options?.body;
    const isBodyEncoded =
      // | BodyInit
      (typeof ReadableStream !== 'undefined' && body instanceof ReadableStream) ||
      // | XMLHttpRequestBodyInit
      body instanceof Blob ||
      body instanceof ArrayBuffer ||
      body instanceof FormData ||
      body instanceof URLSearchParams ||
      (typeof body === 'string' && requestHeaders.has('Content-Type'));

    if (!isBodyEncoded) {
      requestHeaders.append('Content-Type', 'application/json');
    }

    if (authorize) {
      requestHeaders.set('Authorization', getAuthorizationHeaderValue());
    }

    let response: Response;

    // Make request.
    try {
      // This is the only place `fetch` should be called.
      // eslint-disable-next-line no-restricted-syntax
      response = await fetch(url, {
        method,
        headers: requestHeaders,
        body: isBodyEncoded ? body : JSON.stringify(body),
        credentials: options?.credentials || 'include',
        signal: options?.abortSignal,
      });
    } catch (e) {
      // If the user is offline, or some unlikely networking error.
      throw new ApiError('A network error occurred.', 'network.error');
    }

    return response;
  }
}

export default ApiService;
