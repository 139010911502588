import { ApiService } from '@/features/api';

const API_PREFIX = 'v1';

// TODO: when someone finds the BE impl for this API, add a return type definition here.
type OAuthVerifyViewModel = any;

// TODO: when someone finds the BE impl for this API, add a return type definition here.
type OAuthAuthenticateViewModel = any;

export class OAuthService {
  public async Verify(
    domain: string,
    params: Record<string, string>
  ): Promise<OAuthVerifyViewModel> {
    const url = new URL(`${import.meta.env.APP_URLS_API_URL}/${API_PREFIX}/oauth/verify/${domain}`);
    Object.entries(params).forEach(([key, value]) => url.searchParams.append(key, value));
    const verificationResults = await ApiService.post<OAuthVerifyViewModel>(
      url.toString(),
      undefined,
      false
    );
    return verificationResults;
  }

  public async Authenticate(domain: string): Promise<OAuthAuthenticateViewModel> {
    const verificationResults = await ApiService.post<OAuthAuthenticateViewModel>(
      `${import.meta.env.APP_URLS_API_URL}/${API_PREFIX}/oauth/authenticate/${domain}`,
      undefined,
      false
    );
    return verificationResults;
  }
}

export const Instance = new OAuthService();
