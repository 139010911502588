import { Instance as CompanyService } from '@/legacy/services/company';
import { Instance as UserService } from '@/legacy/services/user';

import FeatureFlag from '../types/FeatureFlag';
import FeatureToggleAttributes from '../types/FeatureToggleAttributes';

import { getSplitClient } from './getSplitClient';

type FeaturePromise = Promise<boolean>;

const AnonymousKey = '00000000-0000-0000-0000-000000000000';

/**
 * Provides operations to query the state of feature toggles.
 */
export default class FeatureToggleService {
  private static getClient(key: string): Promise<SplitIO.IBrowserClient> {
    return getSplitClient(key);
  }

  /**
   * Checks if a feature is enabled for a given entity based on its {@link key}.
   * @param featureFlag Feature Flag name.
   * @param key A key identifying an entity for which the current feature state is being requested.
   * @param attributes Additional data associated with a given entity.
   * @returns `true` if feature is enabled, `false` otherwise.
   */
  public static async isFeatureEnabled(
    featureFlag: FeatureFlag,
    key: string,
    attributes?: FeatureToggleAttributes
  ): FeaturePromise {
    try {
      const client = await this.getClient(key);
      if (client === null) {
        return false;
      }

      return client.getTreatment(featureFlag, attributes) === 'on';
    } catch {
      return false;
    }
  }

  /**
   * Checks if a feature is enabled for current company.
   * @param featureFlag Feature Flag name.
   * @param attributes Additional data associated with a given entity.
   * @returns `true` if feature is enabled, `false` otherwise.
   */
  public static async isFeatureEnabledForCurrentCompany(
    featureFlag: FeatureFlag,
    attributes?: FeatureToggleAttributes
  ): FeaturePromise {
    const companyId = CompanyService.CompanyId() ?? AnonymousKey;
    return FeatureToggleService.isFeatureEnabled(featureFlag, companyId, attributes);
  }

  /**
   * Checks if a feature is enabled for current user.
   * @param featureFlag Feature Flag name.
   * @param attributes Additional data associated with a given entity.
   * @returns `true` if feature is enabled, `false` otherwise.
   */
  public static async isFeatureEnabledForCurrentUser(
    featureFlag: FeatureFlag,
    attributes?: FeatureToggleAttributes
  ): FeaturePromise {
    const userId = UserService.UserId() ?? AnonymousKey;
    return FeatureToggleService.isFeatureEnabled(featureFlag, userId, attributes);
  }

  public static async isFeatureEnabledForAnonymous(
    featureFlag: FeatureFlag,
    attributes?: FeatureToggleAttributes
  ): FeaturePromise {
    return FeatureToggleService.isFeatureEnabled(featureFlag, AnonymousKey, attributes);
  }

  /**
   * [For Non-Production Only]
   * Log all of the feature flags and their states for the current company.
   */
  public static async logAllFeatureFlags() {
    const companyId = CompanyService.CompanyId();
    const client = await this.getClient(companyId);
    const treatments = client.getTreatments(Object.values(FeatureFlag));

    // Console printing is intended here.
    // eslint-disable-next-line no-console
    console.log('FeatureFlags (for current company)', treatments);
  }
}
