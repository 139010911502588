interface FieldErrors {
  name: string;
  errors: Array<string>;
}

/**
 * Cambridge Error Field - Relates to Plooto.Data.Errors.CambridgeFxMapError
 */
interface CambrigeErrorField {
  field: string;
  message: string;
}

enum ApiErrorType {
  TooManyRequests = 'error.tooManyRequests',
  ExpiredOAuth = 'expired.oauth',
  InvalidAuthentication = 'invalid.authentication',
  InvalidCompany = 'invalid.companySelection',
  InvalidParameter = 'invalid.parameter',
  BlacklistedAccount = 'blacklisted.account',
  RegionUnsupported = 'region.unsupported',
  PayeeDeleted = 'invalid.payee.deleted',
  InvalidBankFields = 'invalid.bankFields',
  InvalidOverCambridgeLimit = 'invalid.overCambridgeLimit',
}

interface ApiErrorResponse {
  documentation: string;
  error: boolean;
  fields: Array<FieldErrors> | undefined;
  /**
   * Cambridge Error Field List
   * type: @type {Array<CambrigeErrorField>}
   */
  errorFields: Array<CambrigeErrorField> | undefined;
  extraData: Record<string, object>;
  message: string;
  support: string;
  type: ApiErrorType;
}

class ApiError extends Error {
  public type: string;

  public response: ApiErrorResponse;

  public statusCode: number;

  constructor(message: string, type?: string, response?: ApiErrorResponse, statusCode?: number) {
    super(message);
    this.type = type;
    this.response = response;
    this.statusCode = statusCode;

    Object.setPrototypeOf(this, ApiError.prototype);
  }

  /** Shim so that ApiError can stand-in for ApiErrorResponse in legacy utils. */
  public get error() {
    return this.response?.error;
  }

  /** Shim so that ApiError can stand-in for ApiErrorResponse in legacy utils. */
  public get fields() {
    return this.response?.fields;
  }

  /** Shim so that ApiError can stand-in for ApiErrorResponse in legacy utils. */
  public get errorFields() {
    return this.response?.errorFields;
  }
}

export type { ApiErrorResponse };
export { ApiError, ApiErrorType };
