﻿/* eslint-disable prefer-rest-params */
/* eslint-env browser */
/**
 * Add a fallback for Google Analytics to the `window` object.
 *
 * Useful for when Google Analytics is blocked by a browser extension.
 */
(function googleAnalyticsFallback() {
  // Setup temporary Google Analytics objects.
  if (!window.ga) {
    window.GoogleAnalyticsObject = 'ga';
    window.ga = function () {
      (window.ga.q = window.ga.q || []).push(arguments);
    };
    window.ga.l = 1 * new Date();
  }

  // Create a function that wraps `window.ga`.
  // This allows dependant modules to use `window.ga` without knowingly
  // programming against a global object.
  const module = function () {
    window.ga.apply(this, arguments);
  };

  return module;
})();
