import { ComponentType, lazy } from 'react';

/**
 * Like React.lazy(), but for named imports instead of defaults.
 *
 * @see https://github.com/facebook/react/issues/14603#issuecomment-726551598
 * @example
 *
 * const { Home } = lazyImport(() => import('./components/Home'), 'Home');
 */
export function lazyImport<
  T extends ComponentType<any>,
  I extends { [K2 in K]: T },
  K extends keyof I,
>(factory: () => Promise<I>, name: K): I {
  return Object.create({
    [name]: lazy(() => factory().then((module) => ({ default: module[name] }))),
  });
}
