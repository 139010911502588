import * as ko from 'knockout';
import { noop } from 'lodash-es';

// JSDoc in src/@types/knockout/index.d.ts
ko.observable.fn.fromPromise = function fromPromise<T>(promise: Promise<T>) {
  promise.then((value: T) => this(value)).catch(noop);
  return this;
};

// JSDoc in src/@types/knockout/index.d.ts
ko.computed.fn.mapAsync = ko.observable.fn.mapAsync = function mapAsync<TValue, TResult>(
  this: KnockoutReadonlyObservable<TValue>,
  func: (input: TValue, signal: AbortSignal) => Promise<TResult>,
  options?: {
    initialValue?: TResult;
    pendingValue?: TResult;
  }
): KnockoutObservable<TResult> & KnockoutSubscription {
  const observable = ko.observable<TResult>(options?.initialValue);
  let prevController: AbortController;
  const callback = (value: TValue) => {
    const controller = new AbortController();
    prevController?.abort();
    prevController = controller;
    if (options != null && 'pendingValue' in options) {
      observable(options.pendingValue!);
    }
    Promise.resolve()
      .then(() => func(value, controller.signal))
      .then((result) => {
        if (!controller.signal.aborted) {
          observable(result);
        }
      });
  };
  const subscription = this.subscribe(callback);
  const dispose = subscription.dispose.bind(subscription);
  callback(this());
  return Object.assign(observable, { dispose });
};

// JSDoc in src/@types/knockout/index.d.ts
ko.computed.fn.skipUntil = ko.observable.fn.skipUntil = function skipUntil<T>(
  this: KnockoutReadonlyObservable<T>,
  predicate: (value: T) => unknown
): Promise<T> {
  return new Promise<T>((resolve) => {
    let subscription: KnockoutSubscription | undefined;

    const callback = (value: T) => {
      if (predicate(value)) {
        subscription?.dispose();
        resolve(value);
      }
    };

    const initialValue = this();
    if (predicate(initialValue)) {
      resolve(initialValue);
    } else {
      subscription = this.subscribe(callback);
    }
  });
};
