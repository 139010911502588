import * as segmentAnalytics from 'segmentAnalytics';

import CompanyEventService from '@/legacy/services/companyEventService';

import {
  AccountingSoftwareIntegrationEventName,
  AccountingSoftwareIntegrationEventsServiceEventType,
} from './helper';

/**
 * Analytics events listener which triggers Segment Analytics
 * tracking for accounting software integration.
 * This gets called on registerAnalyticsEventsListeners.ts and registers event handlers
 */
const analyticsEventsListener = {
  bind(): void {
    CompanyEventService.on(
      AccountingSoftwareIntegrationEventsServiceEventType.AccountingPlatformConnectionInitiated,
      (payload: CustomEvent) => {
        segmentAnalytics.track(
          AccountingSoftwareIntegrationEventName.AccountingPlatformConnectionInitiated,
          payload?.detail
        );
      }
    );

    CompanyEventService.on(
      AccountingSoftwareIntegrationEventsServiceEventType.AccountingPlatformConnected,
      (payload: CustomEvent) => {
        segmentAnalytics.track(
          AccountingSoftwareIntegrationEventName.AccountingPlatformConnected,
          payload?.detail
        );
      }
    );

    CompanyEventService.on(
      AccountingSoftwareIntegrationEventsServiceEventType.AccountingPlatformDisconnected,
      (payload: CustomEvent) => {
        segmentAnalytics.track(
          AccountingSoftwareIntegrationEventName.AccountingPlatformDisconnected,
          payload?.detail
        );
      }
    );

    CompanyEventService.on(
      AccountingSoftwareIntegrationEventsServiceEventType.AccountingPlatformSync,
      (payload: CustomEvent) => {
        segmentAnalytics.track(
          AccountingSoftwareIntegrationEventName.AccountingPlatformSync,
          payload?.detail
        );
      }
    );
  },
};

export default analyticsEventsListener;
