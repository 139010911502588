import Currency from '@/features/financial/types/Currency';
import CurrencyCode from '@/features/financial/types/CurrencyCode';

const PRIMARY_CURRENCIES: Array<Currency> = [
  {
    code: CurrencyCode.USD,
    name: 'USD - United States Dollar',
    exchangeRatePrecision: 9,
  },
  {
    code: CurrencyCode.CAD,
    name: 'CAD - Canadian Dollar',
    exchangeRatePrecision: 9,
  },
  {
    code: CurrencyCode.EUR,
    name: 'EUR - Euro',
    exchangeRatePrecision: 9,
  },
];

const SECONDARY_CURRENCIES: Array<Currency> = [
  {
    code: CurrencyCode.AED,
    name: 'AED - United Arab Emirates Dirham',
    exchangeRatePrecision: 9,
  },
  {
    code: CurrencyCode.AUD,
    name: 'AUD - Australian Dollar',
    exchangeRatePrecision: 9,
  },
  {
    code: CurrencyCode.BRL,
    name: 'BRL - Brazilian Real',
    exchangeRatePrecision: 9,
  },
  {
    code: CurrencyCode.CHF,
    name: 'CHF - Switzerland Swiss Franc',
    exchangeRatePrecision: 9,
  },
  {
    code: CurrencyCode.CNY,
    name: 'CNY - Chinese Yuan Renminbi',
    exchangeRatePrecision: 9,
  },
  {
    code: CurrencyCode.DKK,
    name: 'DKK - Denmark Danish Krone',
    exchangeRatePrecision: 9,
  },
  {
    code: CurrencyCode.GBP,
    name: 'GBP - British Pound',
    exchangeRatePrecision: 9,
  },
  {
    code: CurrencyCode.HKD,
    name: 'HKD - Hong Kong Dollar',
    exchangeRatePrecision: 9,
  },
  {
    code: CurrencyCode.HRK,
    name: 'HRK - Croatian Kuna',
    exchangeRatePrecision: 9,
  },
  {
    code: CurrencyCode.ILS,
    name: 'ILS - Israeli Shekel',
    exchangeRatePrecision: 9,
  },
  {
    code: CurrencyCode.INR,
    name: 'INR - Indian Rupee',
    exchangeRatePrecision: 9,
  },
  {
    code: CurrencyCode.JPY,
    name: 'JPY - Japanese Yen',
    exchangeRatePrecision: 9,
  },
  {
    code: CurrencyCode.KRW,
    name: 'KRW - South Korean Won',
    exchangeRatePrecision: 8,
  },
  {
    code: CurrencyCode.MXN,
    name: 'MXN - Mexican Peso',
    exchangeRatePrecision: 9,
  },
  {
    code: CurrencyCode.NOK,
    name: 'NOK - Norway Norwegian Kroner',
    exchangeRatePrecision: 9,
  },
  {
    code: CurrencyCode.NZD,
    name: 'NZD - New Zealand Dollar',
    exchangeRatePrecision: 9,
  },
  {
    code: CurrencyCode.PHP,
    name: 'PHP - Philippine Peso',
    exchangeRatePrecision: 9,
  },
  {
    code: CurrencyCode.PLN,
    name: 'PLN - Poland Zloty',
    exchangeRatePrecision: 9,
  },
  {
    code: CurrencyCode.SEK,
    name: 'SEK - Sweden Swedish Krona',
    exchangeRatePrecision: 9,
  },
  {
    code: CurrencyCode.SGD,
    name: 'SGD - Singapore Dollar',
    exchangeRatePrecision: 9,
  },
  {
    code: CurrencyCode.TWD,
    name: 'TWD - New Taiwan Dollar',
    exchangeRatePrecision: 9,
  },
  // UAH: currently not supported/disabled
  {
    code: CurrencyCode.UAH,
    name: 'UAH - Ukrainian Hryvnia',
    exchangeRatePrecision: 9,
  },
  {
    code: CurrencyCode.ZAR,
    name: 'ZAR - South Africa Rand',
    exchangeRatePrecision: 9,
  },
  {
    // NOT SUPPORTED
    code: CurrencyCode.RUB,
    name: 'RUB - Russian Ruble',
    exchangeRatePrecision: 9,
  },
];

SECONDARY_CURRENCIES.sort((firstElement, secondElement) =>
  firstElement.code.localeCompare(secondElement.code, 'en', { sensitivity: 'base' })
);

export { PRIMARY_CURRENCIES, SECONDARY_CURRENCIES };
