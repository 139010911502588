import { createContext } from 'react';

interface UserContextState {
  userId: string;
  email: string;
}

/**
 * Context for the current company.
 */
// We have to use @ts-ignore here because it only errors for strictNullChecks: true.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore `null` is never observed: PlootoPlatformProvider is a parent of all React nodes.
const UserContext = createContext<UserContextState>(null);

export default UserContext;
export type { UserContextState };
