/**
 * Prints Plooto Logo in console log output w/support email address
 */
const consolePrintLogo = (supportEmail = 'support@plooto.com') => {
  console.log(
    `%c-------------------------------------------------------------
$$$$$$$\\  $$\\        $$$$$$\\   $$$$$$\\  $$$$$$$$\\  $$$$$$\\
$$  __$$\\ $$ |      $$  __$$\\ $$  __$$\\ \\__$$  __|$$  __$$\\
$$ |  $$ |$$ |      $$ /  $$ |$$ /  $$ |   $$ |   $$ /  $$ |
$$$$$$$  |$$ |      $$ |  $$ |$$ |  $$ |   $$ |   $$ |  $$ |
$$  ____/ $$ |      $$ |  $$ |$$ |  $$ |   $$ |   $$ |  $$ |
$$ |      $$ |      $$ |  $$ |$$ |  $$ |   $$ |   $$ |  $$ |
$$ |      $$$$$$$$\\  $$$$$$  | $$$$$$  |   $$ |    $$$$$$  |
\\__|      \\________| \\______/  \\______/    \\__|    \\______/
-------------------------------------------------------------
-------- Need help? Contact us at ${supportEmail} --------
-------------------------------------------------------------
`,
    'color: #54c759; font-weight: bold;'
  );
};

export default consolePrintLogo;
